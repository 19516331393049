import { BaseCriteriaModel } from 'src/app/_shared/models/base-criteria.model';
import { BattleActivityFilterModel } from './battle-activity-filter.model';


export class BattleActivityCriteriaModel extends BaseCriteriaModel {
    constructor(data?: BattleActivityCriteriaModel) {
        super(data, {
            filter: BattleActivityFilterModel
        });
    }

    public filter?: BattleActivityFilterModel;
}
