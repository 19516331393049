import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleEntrantResponse, BattleEntrantResponses } from './battle-entrant-responses.dictionary';
import { UserModel } from 'src/app/users/models/user.model';


export class BattleEntrantModel extends BaseModel {
    constructor(data?: BattleEntrantModel) {
        super(data, {
            user: UserModel,
            response: BattleEntrantResponse
        }); // console.log('BattleEntrantModel', this);

        if (!this.response) this.response = new BattleEntrantResponse(BattleEntrantResponses.none);
    }

    public entrantId?: number;
    public battleId?: number;
    public user?: UserModel;
    public response?: BattleEntrantResponse; // = new BattleEntrantResponse(BattleEntrantResponses.none);
    public roundScores?: number[];
    public totalScore?: number;
    public place?: number;
    public votes?: number;
}
