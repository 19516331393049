import { Component, Input } from '@angular/core';
import { BattleModel } from '../../models/battle.model';
import { Dictionary } from 'src/app/_shared/models/_dictionary.model';
import { BattleStatus, BattleStatuses } from '../../models/battle-statuses.dictionary';
import { BattleEntrantResponse, BattleEntrantResponses } from '../../models/battle-entrant-responses.dictionary';
import { AuthService } from 'src/app/auth/auth.service';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleActivityType, BattleActivityTypes } from '../../models/battle-activity-types.dictionary';
import { expandInOut } from 'src/app/_shared/animations';


@Component({
    selector: 'battle-card',
    templateUrl: 'battle-card.component.html',
    styleUrls: ['battle-card.component.scss'],
    animations: [expandInOut()]
})
export class BattleCardComponent {
    constructor(
        public authService: AuthService
    ) { }

    @Input() battle: BattleModel;

    @Input() targetUser: UserModel;

    public battleStatuses: Dictionary<BattleStatus> = BattleStatuses;
    public battleActivityTypes: Dictionary<BattleActivityType> = BattleActivityTypes;
    public battleResponses: Dictionary<BattleEntrantResponse> = BattleEntrantResponses;
}
