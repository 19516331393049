import { BaseModel } from 'src/app/_shared/models/_base.model';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleActivityType } from './battle-activity-types.dictionary';
import { BattleStatus } from './battle-statuses.dictionary';
import { BattleEntrantResponse } from './battle-entrant-responses.dictionary';


export class BattleActivityFilterModel extends BaseModel {
    constructor(data?: BattleActivityFilterModel) {
        super(data, {
            users: [UserModel],
            types: [BattleActivityType],
            statuses: [BattleStatus],
            // entrants: [UserModel],
            responses: [BattleEntrantResponse]
        });
    }

    public key?: string; // Stream

    public users?: UserModel[];
    public types?: BattleActivityType[];

    public statuses?: BattleStatus[];
    public entrants?: UserModel[];
    public responses?: BattleEntrantResponse[];

    public battleId?: number;

    // public isFollowingOwner?: boolean;
    // public isListChallenge?: boolean;
}
