import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProfilePicComponent } from './profile-pic.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [
    ProfilePicComponent
  ],
  exports: [
    ProfilePicComponent
  ]
})
export class ProfilePicModule {}
