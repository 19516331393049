import { Dictionary, DictionaryDefinition } from '../../_shared/models/_dictionary.model';


export class BattleScoreOrder extends DictionaryDefinition {
    constructor(data?: string) {
        super(data, BattleScoreOrders);
    }
}

export const BattleScoreOrders: Dictionary<BattleScoreOrder> = {
    ascending: {
        key: 'ascending',
        char: 'A',
        label: 'Lowest Wins',
        index: 0
    },
    descending: {
        key: 'descending',
        char: 'D',
        label: 'Highest Wins',
        index: 1
    }
};
