import { Component, OnInit, Inject, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { LoadingController, ToastController, NavController, IonSlides } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { BasePage } from '../_shared/components/base.page';
import { AuthService } from './auth.service';
import { NgForm } from '@angular/forms';
import { BaseWalkThruPage } from '../_shared/components/base-walkthru.page';
import { validateForm } from '../_shared/helpers/forms';
import { AlertsComponent } from '../_shared/components/alerts/alerts.component';


@Component({
    selector: 'app-login',
    templateUrl: 'login.page.html',
    styleUrls: ['login.page.scss'],
})
export class LoginPage extends BasePage implements OnInit {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        // private router: Router,
        private navigation: NavController,
        private storageService: Storage,
        private toastController: ToastController,
        public loadingController: LoadingController,
        public authService: AuthService // AngularSocialLoginService
    ) { super(loadingController); }

    public isInitialised: boolean;

    public walkThruTab: number = 0;
    @ViewChild('walkThru', { static: true }) public walkThru: IonSlides;
    @ViewChild('signInTabs', { static: true }) public signInTabs: IonSlides;

    async ngOnInit() {
        super.ngOnInit();
        await this.startLoading('full');
        await this.autoLogin();
        this.onLoginComplete();
        this.stopLoading('full');
        this.isInitialised = true;

        /* this.authService.authState.subscribe((user) => { console.log('user', user);
            this.user = user;
        }); */

        setTimeout(async () => {
            /* this.signIn.ionSlideDidChange.subscribe(async () => {
                this.signInTab = await this.signIn.getActiveIndex();
            }); */

            // Init sub-sections
            // this.signIn.init(this.signInTabs);

            // Attempt to fix slides
            await this.walkThru.update();
            await this.signInTabs.update();

            this.walkThru.ionSlideWillChange.subscribe(async () => { // console.log(this.walkThru);
                this.walkThruTab = await this.walkThru.getActiveIndex();
            });
        });
    }

    public async autoLogin() { console.log('autoLogin');
        try {
            // LocalStored Token
            const existingToken = await this.storageService.get('Authorization');
            if (existingToken) await this.authService.login('token', existingToken);
            else {
                // Read Oath callback query params
                const queryParams: any = ((this.document.location.hash || '').split('?')[1] || '').split('&').map(val => val.split('=')).reduce((mem, val) => {
                    mem[val[0]] = val[1];
                    return mem;
                }, {});

                /* if (!this.authService.redirectUrl) return;
                const queryString = this.authService.redirectUrl.split('?')[1];
                if (!queryString) return;
                const queryParams: any = queryString.split('&').map(val => val.split('=')).reduce((mem, val) => {
                    mem[val[0]] = val[1];
                    return mem;
                }, {}); */

                if (queryParams.oauth) { // Ouath Callback
                    await this.authService.login(queryParams.oauth, queryParams.code);
                }
            }
        } catch (error) { console.warn(error);
            this.social.alerts.set('error', error);
            /* .toastController.create({
                message: error.message,
                duration: 5000
            }).then(toast => toast.present()); */
        }
    }

    /* public async manualLogin(provider, form?: NgForm) {
        const loading = await this.startLoading();
        // try {
            switch (provider) {
                case 'credentials':
                    if (!form.valid) throw new Error('Form Invalid');
                    await this.authService.login(provider, form.value)
                    .then(() => this.onLoginComplete())
                    .catch(error => this.alerts.set('error', error));
                    break;
                case 'facebook':
                    await this.authService.signInWithFB()
                    .then(() => this.onLoginComplete())
                    .catch(error => this.alerts.set('error', error));
                    break; // Success redirects to FB
            }
        } catch (error) { console.warn(error);
            this.toastController.create({
                message: error.message,
                duration: 5000
            }).then(toast => toast.present());
        }
        // await this.onLoginComplete();
        this.stopLoading();
    } */

    /* private async startLoading() {
        this.renderer.addClass(this.document.body, 'blurred');
        const loading = await this.loadingController.create({
            // duration: 5000,
            // message: 'Please wait...',
            // translucent: true,
            cssClass: 'transparent'
        });
        loading.present();
        return loading;
    }

    public stopLoading(loading: HTMLIonLoadingElement) {
        loading.dismiss();
        this.renderer.removeClass(this.document.body, 'blurred');
    } */

    private async onLoginComplete() {
        if (!this.authService.user) return;
        const redirectUrl = this.authService.redirectUrl || '';
        delete this.authService.redirectUrl;
        return this.navigation.navigateRoot(redirectUrl);
        // return this.router.navigate([redirectUrl]);
    }

    // Social
    public social = new class Social extends BasePage {
        constructor(
            private parent: LoginPage
        ) { super(parent.loadingController); }

        // alerts = new AlertsComponent();

        async login(provider: string) {
            this.alerts.reset();
            await this.startLoading();
            switch (provider) {
                case 'facebook':
                    await this.parent.authService.signInWithFB()
                        .then(() => this.parent.onLoginComplete())
                        .catch(error => this.alerts.set('error', error));
                    break;
            }
            await this.stopLoading();
        }
    }(this);

    // Sign-In
    public signInTab: number = 0;
    @ViewChild('signInForm', { static: false }) signInForm: NgForm;
    public signIn = new class SignIn extends BasePage {
        constructor(
            private parent: LoginPage
        ) { super(parent.loadingController); }

        async submit() {
            // Validate
            this.alerts.reset();
            setTimeout(() => this.parent.signInTabs.updateAutoHeight());
            if (!validateForm(this.parent.signInForm.form)) return;
            // this.parent.signInForm.form.updateValueAndValidity();
            // if (!this.parent.signInForm.valid) return; // throw new Error('Form Invalid');

            await this.startLoading();
            await this.parent.authService.login('credentials', this.parent.signInForm.value)
                .then(() => this.parent.onLoginComplete())
                .catch(error => this.alerts.set('error', error));
            await this.stopLoading();
        }
    }(this);

    @ViewChild('signUpForm', { static: false }) signUpForm: NgForm;
    public signUp = new class Credential extends BasePage {
        constructor(
            private parent: LoginPage
        ) { super(parent.loadingController); }

        async submit() {
            // Validate
            this.alerts.reset();
            setTimeout(() => this.parent.signInTabs.updateAutoHeight());
            if (!validateForm(this.parent.signUpForm.form)) return;
            // this.parent.signUpForm.form.updateValueAndValidity();
            // if (!this.parent.signInForm.valid) return; // this.alerts.showFormErrors(this.parent.signUpForm.form);

            await this.startLoading();
            await this.parent.authService.signUp(this.parent.signUpForm.value)
                .then(() => this.parent.onLoginComplete())
                .catch(error => this.alerts.set('error', error));
            await this.stopLoading();
        }
    }(this);
}
