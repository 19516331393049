import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavController, LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';

import { animateList } from 'src/app/_shared/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { BattleService } from '../../battle.service';
import { BattleListPage } from '../battle-list.page';


@Component({
    selector: 'battle-home',
    templateUrl: 'battle-home.page.html',
    // styleUrls: ['battle-home.page.scss'],
    animations: [animateList()]
})
export class BattleHomePage extends BattleListPage {
    constructor(
        // public navController: NavController,
        public loadingController: LoadingController,
        // public popoverController: PopoverController,
        public authService: AuthService,
        public battleService: BattleService
    ) { super(loadingController, authService, battleService); }

    public groupProperty = 'when';

    /* public criteria: BehaviorSubject<BattleCriteriaModel> = new BehaviorSubject(null);

    public battles: { [filter: string]: GroupedArray<BattleModel> } = {};

    ionViewWillEnter() {
        this.criteria.pipe(takeUntil(this._onDestroy))
        .subscribe(() => this.refresh());
    }

    public async refresh($event?) {
        delete this.battles[this.criteria.value.filter.key];
        this.criteria.value.skip = 0;
        await this.getBattles(this.criteria.value);
        if ($event) $event.target.complete();
    }

    public async getBattles(criteria: BattleCriteriaModel, $event?) {
        await this.startLoading('partial');
        this.alerts.reset();

        if (!this.battles[criteria.filter.key]) {
            this.battles[criteria.filter.key] = new GroupedArray({
                getKey: battle => +format((battle.when || new Date()), 'yyyyMMdd'), // Math.round((battle.when || new Date()).getTime() / 86400000),
                getLabel: battle => format((battle.when || new Date()), 'MMM do')
            });
        } else {
            criteria.skip += criteria.take;
        }

        if (!this.battles[criteria.filter.key].hasNoMore) await this.battleService.getBattles(criteria)
        .then(response => this.battles[criteria.filter.key].merge(response))
        .catch(error => this.alerts.set('error', error));
        this.stopLoading();
        if ($event) $event.target.complete();
    }

    trackByFn(index: number) {
        return index;
    } */
}
