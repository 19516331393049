import { UserModel } from 'src/app/users/models/user.model';
import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleActivityType, BattleActivityTypes } from './battle-activity-types.dictionary';
import { BattleEntrantResponse } from './battle-entrant-responses.dictionary';
import { BattleModel } from './battle.model';


export class BattleActivityModel extends BaseModel {
    constructor(data?: BattleActivityModel, dictionary: any = {}) {
        super(data, Object.assign({
            user: UserModel,
            // battle: BattleModel,
            type: BattleActivityType
        }, dictionary)); // console.log('BattleActivityModel', this);

        if (this.type.key === BattleActivityTypes.responded.key) {
            this.content = new BattleEntrantResponse(this.content);
        }
    }

    public battleActivityId?: number;
    public battleId?: number;
    public battle?: BattleModel;
    public user?: UserModel;
    public created?: Date;
    public type?: BattleActivityType;
    public content?: string | any;
}
