import { Component, Input, OnInit } from '@angular/core';
import { BattleModel } from '../../models/battle.model';
import { Dictionary } from 'src/app/_shared/models/_dictionary.model';
import { BattleStatus, BattleStatuses } from '../../models/battle-statuses.dictionary';
import { BattleEntrantResponse, BattleEntrantResponses } from '../../models/battle-entrant-responses.dictionary';
import { AuthService } from 'src/app/auth/auth.service';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleActivityType, BattleActivityTypes } from '../../models/battle-activity-types.dictionary';
import { BattleActivityModel } from '../../models/battle-activity.model';
import { BattleScoreType, BattleScoreTypes } from '../../models/battle-score-type.dictionary';
import { BaseComponent } from 'src/app/_shared/components/base.component';
import { LoadingController } from '@ionic/angular';


@Component({
    selector: 'battle-activity-card',
    templateUrl: 'battle-activity-card.component.html',
    styleUrls: ['battle-activity-card.component.scss']
})
export class BattleActivityCardComponent extends BaseComponent implements OnInit {
    constructor(
        public loadingController: LoadingController,
        public authService: AuthService
    ) { super(loadingController); }

    @Input() isDetailed: boolean = true;

    @Input() activity: BattleActivityModel;

    @Input() battle: BattleModel;

    @Input() targetUser: UserModel;

    public statuses: Dictionary<BattleStatus> = BattleStatuses;
    public activityTypes: Dictionary<BattleActivityType> = BattleActivityTypes;
    public responses: Dictionary<BattleEntrantResponse> = BattleEntrantResponses;
    public scoreTypes: Dictionary<BattleScoreType> = BattleScoreTypes;

    ngOnInit() {
        if (!this.battle) this.battle = this.activity.battle;
    }
}
