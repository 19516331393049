import { Component, Input } from '@angular/core';
import { BattleModel } from '../../models/battle.model';
import { Dictionary } from 'src/app/_shared/models/_dictionary.model';
import { BattleStatus, BattleStatuses } from '../../models/battle-statuses.dictionary';
import { BattleEntrantResponse, BattleEntrantResponses } from '../../models/battle-entrant-responses.dictionary';


@Component({
    selector: 'battle-list-item',
    templateUrl: 'battle-list-item.component.html',
    styleUrls: ['battle-list-item.component.scss']
})
export class BattleListItemComponent {
    constructor(
    ) { }

    @Input() battle: BattleModel;

    public battleStatuses: Dictionary<BattleStatus> = BattleStatuses;
    public battleResponses: Dictionary<BattleEntrantResponse> = BattleEntrantResponses;
}
