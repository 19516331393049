import { BaseModel } from 'src/app/_shared/models/_base.model';


export class UserFilterModel extends BaseModel {
    constructor(data?: UserFilterModel) {
        super(data, {
            // statuses: [BattleStatus]
        });
    }

    public query?: string;

    public userIds?: number[];
}
