import { BaseCriteriaModel } from 'src/app/_shared/models/base-criteria.model';
import { BattleCommentFilterModel } from './battle-comment-filter.model';


export class BattleCommentCriteriaModel extends BaseCriteriaModel {
    constructor(data?: BattleCommentCriteriaModel) {
        super(data, {
            filter: BattleCommentFilterModel
        });
    }

    public filter?: BattleCommentFilterModel;
}
