import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController, NavController, PopoverController, IonToggle } from '@ionic/angular';

import { BasePage } from 'src/app/_shared/components/base.page';
import { expandInOut } from 'src/app/_shared/animations';
import { UserModel } from 'src/app/users/models/user.model';
import { UserService } from '../user.service';
import { AuthService } from 'src/app/auth/auth.service';
import { BattleService } from 'src/app/battles/battle.service';
import { AuthUserModel } from '../models/auth-user.model';
import { NgForm, FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'user-settings',
    templateUrl: 'user-settings.page.html',
    styleUrls: ['user-settings.page.scss'],
    animations: [expandInOut()],
    encapsulation: ViewEncapsulation.None
})
export class UserSettingsPage extends BasePage {
    constructor(
        public route: ActivatedRoute,
        public loadingController: LoadingController,
        public navController: NavController,
        public popoverController: PopoverController,
        public authService: AuthService,
        public userService: UserService
    ) { super(loadingController); }

    public user: AuthUserModel = this.authService.user;

    // Privacy
    @ViewChild('ngForm', { static: true }) ngForm: NgForm;
    public async toggleAnonymousChallenges(isAllowed: boolean) { console.log(isAllowed);
        this.alerts.reset();
        await this.startLoading();
        await this.authService.allowAnonymousChallenges(isAllowed)
        .catch(error => {
            this.alerts.set('error', 'There was an issue saving the changes');
            this.ngForm.controls.allowAnonymousChallenges.setValue(!isAllowed, { emitViewToModelChange: false });
        });
        this.stopLoading();
    }

    // Account
    @ViewChild('passwordForm', { static: false }) passwordForm: NgForm;
    public changePassword = new class ChangPassword extends BasePage {
        constructor(
            private parent: UserSettingsPage
        ) { super(parent.loadingController); }

        public isOpen: boolean;

        public async save() { // ngForm: NgForm) { // console.log(ngForm.form.value);
            const ngForm = this.parent.passwordForm;

            // Validate
            this.alerts.reset();
            ngForm.form.updateValueAndValidity();
            if (!ngForm.form.value.newPassword) {
                this.alerts.set('warning', 'Enter a password');
                return;
            } else if (ngForm.form.value.newPassword !== ngForm.form.value.confirmPassword) {
                this.alerts.set('warning', 'Passwords don\'t match');
                return;
            }

            await this.startLoading();
            await this.parent.authService.changePassword(ngForm.form.value)
            .then((response) => { console.log(response);
                this.alerts.set('success', 'Your new password has been saved.');
                this.parent.passwordForm.reset();
                delete this.isOpen;
            })
            .catch(error => {
                this.alerts.set('error', error || 'There was an issue saving the changes');
                // this.passwordError = error;
            });
            this.stopLoading();
        }
    }(this);

    // public passwordError: any;
}
