import { Component, OnInit, ViewChild } from '@angular/core';
import { get } from 'lodash-es';

import { AuthService } from '../../auth/auth.service';
import { IonSlides, MenuController } from '@ionic/angular';
import { UserService } from 'src/app/users/user.service';
import { UserModel } from 'src/app/users/models/user.model';
import { expandInOut } from '../animations';


@Component({
    selector: 'logged-in',
    templateUrl: 'logged-in.component.html',
    styleUrls: ['logged-in.component.scss'],
    animations: [expandInOut()]
})
export class LoggedInComponent {
    constructor(
        private menu: MenuController,
        public authService: AuthService,
        public userService: UserService
        // private socketService: SocketService
    ) { }

    public appPages = [
        {
            title: 'Home',
            url: '/',
            icon: 'home'
        }, {
            title: 'Invites',
            url: '/invites',
            icon: 'paper-plane' // filing' // mail'
        }, {
            title: 'Upcoming',
            url: '/battles',
            urlParams: { filter: 'upcoming' },
            icon: 'flag'
        }, {
            title: 'Results',
            url: '/battles',
            urlParams: { filter: 'results' },
            icon: 'medal'
        }, {
            title: 'Lists',
            url: '/lists',
            icon: 'trophy'
        }, {
            title: 'Leagues',
            url: '/leagues',
            icon: 'planet'
        }
    ];

    ionViewWillEnter() { console.log('LoggedInComponent.init()', this.menu);
    this.menu.enable(true, 'user');
    this.menu.enable(true, 'user');
        this.menu.enable(true, 'friends');
    }

    public currentPage: Component;

    onActivate($event: Component) {
        this.currentPage = $event;
    }

    @ViewChild('newFollowersSlides', { static: false }) newFollowersSlides: IonSlides;
    public newFollowers = new class NewFollowers {
        constructor(private parent: LoggedInComponent) {}

        users: UserModel[];

        async init() {
            this.users = await this.parent.userService.getFollowers(this.parent.authService.user.userId);
            // if (get(this.users, 'length')) this.parent.newFollowersSlides.update();
        }

        async followBack(user: UserModel) {
            await this.parent.userService.follow([user]);
            this.users.splice(0, 1);
            this.parent.newFollowersSlides.update();
        }
    }(this);

}
