import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertsComponent } from './alerts.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [
    AlertsComponent
  ],
  exports: [
    AlertsComponent
  ]
})
export class AlertsModule {}
