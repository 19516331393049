import { Dictionary, DictionaryDefinition } from '../../_shared/models/_dictionary.model';


export class BattlePrivacy extends DictionaryDefinition {
    constructor(data?: string) {
        super(data, BattlePrivacies);
    }
}

export const BattlePrivacies: Dictionary<BattlePrivacy> = {
    entrants: {
        key: 'entrants',
        char: 'E',
        label: 'Visible to entrants only',
        index: 0
    },
    friends: {
        key: 'friends',
        char: 'F',
        label: 'Visible to friends of entrants',
        index: 1
    },
    anyone: {
        key: 'anyone',
        char: 'A',
        label: 'Visible to anyone',
        index: 2
    }
    /* private: {
        key: 'private',
        char: 'R',
        label: 'Private',
        index: 0
        // icon: 'fa-icon'
    },
    public: {
        key: 'public',
        char: 'L',
        label: 'Public',
        index: 1
    },
    open: {
        key: 'open',
        char: 'O',
        label: 'Open',
        index: 2
    } */
};
