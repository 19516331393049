import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { BaseModel } from 'src/app/_shared/models/_base.model';


export class BattleStreamModel extends BaseModel {
    constructor(data?: BattleStreamModel, dictionary: any = {}) {
        super(data, Object.assign({
            // url: (val) => DomSanitizer.bypassSecurityTrustUrl(val)
        }, dictionary)); // console.log('BattleStreamModel', this);
    }

    public streamId?: number;
    public url?: string | SafeUrl;
    public startTime?: number;
}
