import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseFilterMenuComponent } from 'src/app/_shared/components/base-filter.menu';
import { expandInOut } from 'src/app/_shared/animations';
import { BattleCriteriaModel } from 'src/app/battles/models/battle-criteria.model';
import { AuthService } from 'src/app/auth/auth.service';
import { BattleStatuses, BattleStatus } from 'src/app/battles/models/battle-statuses.dictionary';
import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { LoadingController } from '@ionic/angular';


@Component({
    selector: 'battle-home-filter',
    templateUrl: 'battle-home-filter.component.html',
    animations: [expandInOut()]
})
export class BattleHomeFilterComponent extends BaseFilterMenuComponent implements OnInit {
    constructor(
        public route: ActivatedRoute,
        // public router: Router,
        public location: Location,
        public authService: AuthService,
        public loadingController: LoadingController
    ) { super(loadingController); }

    ngOnInit() {
        super.ngOnInit();
        this.route.queryParams.pipe(takeUntil(this._onDestroy))
        .subscribe(queryParams => { console.log(queryParams.filter);
            this.setCriteria(this.criterias.findIndex(criteria => {
                return criteria.filter.key === queryParams.filter;
            }));
        });
    }

    public criterias: BattleCriteriaModel[] = [
        new BattleCriteriaModel({
            label: 'Upcoming',
            /* order: [{
                key: 'upcoming',
                // label: 'Upcoming'
            }], */
            filter: {
                key: 'upcoming',
                entrants: [this.authService.user],
                statuses: [BattleStatuses.inProgress, BattleStatuses.upcoming], // BattleStatuses.finished],
                // responses: []
            }
        }),
        new BattleCriteriaModel({
            label: 'Results',
            /* order: [{
                key: 'results',
                // label: 'Upcoming'
            }], */
            filter: {
                key: 'results',
                entrants: [this.authService.user],
                statuses: [BattleStatuses.finished],
                responses: []
            }
        })
    ];

    public setCriteria = (index: number) => {
        const criteria = this.criterias[index];
        this.statusOptions.forEach(option => {
            option.isSelected = !criteria.filter.statuses.length || !!criteria.filter.statuses.find(status => status.key === option.key);
        });
        /* this.responseOptions.forEach(option => {
            option.isSelected = !!criteria.filter.responses.find(response => response.key === option.key);
        }); */

        this.tempCriteria = criteria;
        this.criteria.next(this.tempCriteria); // this.setCriteria(this.tempCriteria);
        this.slideTab = index;
        this.slideTabs.slideTo(index);
        this.buildLabel();
        /* this.router.navigate(['battles'], {
            queryParams: {
                filter: criteria.filter.key
            },
            skipLocationChange: true
        }); */
        this.location.go('battles', 'filter=' + criteria.filter.key);
    }

    public buildLabel = () => {
        this.buttonLabel = this.tempCriteria.label; /* (() => {
            if (!this.tempCriteria.filter.statuses.length || this.tempCriteria.filter.statuses.length === this.statusOptions.length)
                return 'All';
            else
                return this.tempCriteria.filter.statuses.map(status => status.label).join(', '); // criteria.label;
        })(); */
    }

    public statusOptions: BattleStatus[] = [
        BattleStatuses.inProgress,
        BattleStatuses.upcoming,
        BattleStatuses.finished
    ];

    /* public orderOptions: any[] = [
        {
            key: 'newest',
            label: 'Newest'
        }, {
            key: 'upcoming',
            label: 'Upcoming'
        }
    ]; */
}
