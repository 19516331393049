import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonSlides, LoadingController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash-es';

import { BattleCriteriaModel } from 'src/app/battles/models/battle-criteria.model';
import { BaseCriteriaModel } from '../models/base-criteria.model';
import { BaseComponent } from './base.component';


export class BaseFilterMenuComponent extends BaseComponent implements OnInit {
    constructor(
        // public route: ActivatedRoute,
        public loadingController: LoadingController
    ) { super(loadingController); }

    ngOnInit() {
        super.ngOnInit();
        // if (!this.criteria.value) {
            this.setCriteria(0);
            // this.criteria.next(this.tempCriteria);
            // this.buildLabel();
        // }
    }

    // slideTabs: IonSlides;
    @Input() @ViewChild('slideTabs', { static: true }) public slideTabs: IonSlides;
    public slideTab: number = 0;

    public criterias: BaseCriteriaModel[];
    @Input() criteria: BehaviorSubject<BaseCriteriaModel>;
    public tempCriteria: BaseCriteriaModel;

    public buttonLabel: string;
    public showMenu: boolean;

    public toggleMenu() {
        this.showMenu = !this.showMenu;
        if (this.showMenu) {
            // this.setCriteria(cloneDeep(this.criteria.value));
        } else {
            if (JSON.stringify(this.tempCriteria) !== JSON.stringify(this.criteria.value)) {
                this.criteria.next(this.tempCriteria); // this.setCriteria(this.tempCriteria);
                this.buildLabel();
            }
        }
    }

    public setCriteria: (index: number) => void;

    public buildLabel: () => void;

    public toggleOption(selectedOption: any, options: any[], comparator: string = 'key'): void {
        const selectedIndex = options.findIndex(option => option[comparator] === selectedOption[comparator]);
        if (selectedIndex >= 0)
            options.splice(selectedIndex, 1);
        else
            options.push(selectedOption);
    }
}
