import { Component } from '@angular/core';

import { UserService } from 'src/app/users/user.service';
import { UserModel } from 'src/app/users/models/user.model';


@Component({
    selector: 'user-detail-menu',
    templateUrl: 'user-detail.menu.html'
})
export class UserDetailMenuComponent {
    constructor(
        public userService: UserService
    ) { }

    public user: UserModel;

    /* public async toggleFollowing() {
        // await this.startLoading('partial');
        // await new Promise((resolve) => setTimeout(() => resolve(), 10000));
        // const selectedFriends = this.friends.filter(friend => friend.isSelected);
        if (this.user.security.isFollowing)
            await this.userService.unfollow([this.user]);
        else
            await this.userService.follow([this.user]);

        this.user.security.isFollowing = !this.user.security.isFollowing;

        // .then(() => { this.isCompleted = true; })
        // .catch(error => this.alerts.set('error', error));
        // await this.stopLoading();
        // return !this.alerts.error;
    } */
}
