import { BaseCriteriaModel } from 'src/app/_shared/models/base-criteria.model';
import { BattleFilterModel } from './battle-filter.model';


export class BattleCriteriaModel extends BaseCriteriaModel {
    constructor(data?: BattleCriteriaModel) {
        super(data, {
            filter: BattleFilterModel
        });
    }

    public filter?: BattleFilterModel;
}
