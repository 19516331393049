import { NgModule } from '@angular/core';

import { IonSlideExtrasDirective } from './ion-slide-extras.directive';
import { ValidationDirective } from './validation.directive';

@NgModule({
  declarations: [
    IonSlideExtrasDirective, ValidationDirective
  ],
  exports: [
    IonSlideExtrasDirective, ValidationDirective
  ]
})
export class DirectivesModule {}
