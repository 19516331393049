import { BaseModel } from 'src/app/_shared/models/_base.model';
import { UserStatus } from './user-statuses.dictionary';
import { UserSecurityModel } from './user-security.model';
import { BattleCategoryModel } from 'src/app/battles/models/battle-category.model';
import { UserStatsModel } from './user-stats.model';


export class UserModel extends BaseModel {
    constructor(data?: UserModel, dictionary: any = {}) {
        super(data, Object.assign({
            status: UserStatus,
            security: UserSecurityModel,
            friends: [UserModel],
            followers: [UserModel],
            following: [UserModel],
            stats: UserStatsModel,
        }, dictionary)); // console.log('UserModel', this);
    }

    public userId?: number;
    public name?: string;
    public firstName?: string;
    public lastName?: string;
    public pictureUrl?: string;
    public status?: UserStatus;
    public interests?: BattleCategoryModel[];
    public security?: UserSecurityModel;
    public friends?: UserModel[];
    public followers?: UserModel[];
    public following?: UserModel[];
    public stats?: UserStatsModel;
}
