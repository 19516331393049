import { BaseModel } from 'src/app/_shared/models/_base.model';


export class BattleCommentFilterModel extends BaseModel {
    constructor(data?: BattleCommentFilterModel) {
        super(data);
    }

    public battleId?: number;
}
