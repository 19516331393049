import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash-es';
import { BattleModel } from '../../models/battle.model';
import { Dictionary } from 'src/app/_shared/models/_dictionary.model';
import { BattleStatus, BattleStatuses } from '../../models/battle-statuses.dictionary';
import { BattleEntrantResponse, BattleEntrantResponses } from '../../models/battle-entrant-responses.dictionary';
import { AuthService } from 'src/app/auth/auth.service';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleActivityType, BattleActivityTypes } from '../../models/battle-activity-types.dictionary';
import { BattleActivityModel } from '../../models/battle-activity.model';
import { BattleScoreType, BattleScoreTypes } from '../../models/battle-score-type.dictionary';


@Component({
    selector: 'battle-activity-content',
    templateUrl: 'battle-activity-content.component.html',
    styleUrls: ['battle-activity-content.component.scss']
})
export class BattleActivityContentComponent implements OnInit, OnChanges {
    constructor(
        public authService: AuthService
    ) { }

    @Input() activity: BattleActivityModel;

    @Input() battle: BattleModel;

    @Input() targetUser: UserModel;

    public statuses: Dictionary<BattleStatus> = BattleStatuses;
    public activityTypes: Dictionary<BattleActivityType> = BattleActivityTypes;
    public responses: Dictionary<BattleEntrantResponse> = BattleEntrantResponses;
    public scoreTypes: Dictionary<BattleScoreType> = BattleScoreTypes;

    ngOnChanges(changes: SimpleChanges) { // console.log('onChanges', this.battle);
        if (this.activity && !this.battle)
            this.battle = this.activity.battle;

        if (get(this.battle, 'entrants.length') === 2) {
            this.targetUser = (() => {
                if (this.battle.owner.userId === this.authService.user.userId)
                    return (this.battle.entrants.find(entrant => entrant.user.userId !== this.authService.user.userId) || {}).user;
                else
                    return (this.battle.entrants.find(entrant => entrant.user.userId !== this.battle.owner.userId) || {}).user;
            })();
            console.log(this.battle.what, this.targetUser);
        }
    }

    ngOnInit() {
    }
}
