import { Component, Input, OnInit, SimpleChanges, OnChanges, AfterViewInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash-es';

import { BaseFilterMenuComponent } from 'src/app/_shared/components/base-filter.menu';
import { expandInOut } from 'src/app/_shared/animations';
import { BattleCriteriaModel } from 'src/app/battles/models/battle-criteria.model';
import { AuthService } from 'src/app/auth/auth.service';
import { BattleEntrantResponse, BattleEntrantResponses } from 'src/app/battles/models/battle-entrant-responses.dictionary';
import { BattleStatuses, BattleStatus } from 'src/app/battles/models/battle-statuses.dictionary';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleActivityCriteriaModel } from 'src/app/battles/models/battle-activity-criteria.model';
import { BattleActivityFilterModel } from 'src/app/battles/models/battle-activity-filter.model';
import { BattleService } from 'src/app/battles/battle.service';
import { BattleActivityModel } from 'src/app/battles/models/battle-activity.model';
import { BattleModel } from 'src/app/battles/models/battle.model';
import { LoadingController } from '@ionic/angular';
import { Dictionary } from 'src/app/_shared/models/_dictionary.model';
import { BattleScoreType, BattleScoreTypes } from 'src/app/battles/models/battle-score-type.dictionary';


@Component({
    selector: 'user-battles-filter',
    templateUrl: 'user-battles-filter.component.html',
    animations: [expandInOut()]
})
export class UserBattlesFilterComponent extends BaseFilterMenuComponent implements OnInit, AfterViewInit, OnChanges {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        public route: ActivatedRoute,
        public authService: AuthService,
        public battleService: BattleService,
        public loadingController: LoadingController
    ) { super(loadingController); }

    @Input() user: UserModel;

    public currentCriteria: BehaviorSubject<BattleCriteriaModel> = new BehaviorSubject(null);

    public results: { [filter: string]: (BattleModel | BattleActivityModel)[] } = {};

    public statuses: Dictionary<BattleStatus> = BattleStatuses;
    public responses: Dictionary<BattleEntrantResponse> = BattleEntrantResponses;
    public scoreTypes: Dictionary<BattleScoreType> = BattleScoreTypes;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.user && this.criterias) { console.log(this.user);
            // this.criterias[1].label = this.user.security.isFollowing ? 'Others' : 'Filter';
            // this.setCriteria(this.user.security.isFollowing ? 0 : 1);
        }
    }

    ngOnInit() {
        this.criterias = [
            new BattleActivityCriteriaModel({
                label: 'Activity',
                filter: new BattleActivityFilterModel({
                    users: (() => {
                        const users = [{ userId: +this.route.snapshot.params.userId }];
                        // if (users[0].userId !== this.authService.user.userId)
                        //     users.push({ userId: this.authService.user.userId });
                        return users;
                    })(),
                    statuses: [],
                    responses: []
                }),
                selection: {
                    isDetailed: true
                },
                fetch: async (force) => {
                    const criteria = this.criterias[this.slideTab];
                    return this.battleService.getActivity({
                        filter: criteria.filter,
                        selection: criteria.selection,
                        skip: criteria.skip
                        /* new BattleActivityCriteriaModel({
                            filter: {
                                users: (() => {
                                    const users = [{ userId: +this.route.snapshot.params.userId }];
                                    // if (users[0].userId !== this.authService.user.userId)
                                    //     users.push({ userId: this.authService.user.userId });
                                    return users;
                                })(),
                                statuses: [],
                                responses: []
                            },
                            selection: {
                                isDetailed: true
                            }
                        }) */ }, force);
                }
            }),
            new BattleCriteriaModel({
                label: 'Events',
                filter: {
                    key: 'ours',
                    entrants: [this.user], // , this.authService.user],
                    statuses: [], // BattleStatuses.upcoming],
                    responses: [] // BattleEntrantResponses.none, BattleEntrantResponses.accept]
                },
                selection: {
                   // isDetailed: true
                },
                fetch: async (force) => {
                    const criteria = this.criterias[this.slideTab];
                    return this.battleService.getBattles({
                        filter: criteria.filter,
                        selection: criteria.selection,
                        skip: criteria.skip
                        /* new BattleCriteriaModel({
                            filter: {
                                key: 'ours',
                                entrants: [this.user], // , this.authService.user],
                                statuses: [], // BattleStatuses.upcoming],
                                responses: [] // BattleEntrantResponses.none, BattleEntrantResponses.accept]
                            }
                        }) */ }, force);
                }
            })
            /* new BattleCriteriaModel({
                label: 'Our Match-Up\'s',
                filter: {
                    key: 'ours',
                    entrants: [this.user, this.authService.user],
                    statuses: [], // BattleStatuses.upcoming],
                    responses: [] // BattleEntrantResponses.none, BattleEntrantResponses.accept]
                }
            }),
            new BattleCriteriaModel({
                // label: 'Others',
                filter: {
                    key: 'others',
                    entrants: [this.user],
                    statuses: [], // BattleStatuses.upcoming],
                    responses: [] // BattleEntrantResponses.none, BattleEntrantResponses.accept]
                }
            }) */
        ];
        super.ngOnInit();
    }

    ngAfterViewInit() {
       // this.setCriteria(0);
        // super.ngOnInit();
    }

    public setCriteria = (index: number) => { console.log('setCriteria', index);
        this.slideTab = index;
        // this.slideTabs.slideTo(index);
        this.currentCriteria.next(this.criterias[this.slideTab]);
        /* this.statusOptions.forEach(option => {
            option.isSelected = !!criteria.filter.statuses.find(status => status.key === option.key);
        });
        this.responseOptions.forEach(option => {
            option.isSelected = !!criteria.filter.responses.find(response => response.key === option.key);
        }); */

        // this.tempCriteria = criteria;
        // this.criteria.next(this.tempCriteria);
        this.buildLabel();
    //    this.triggerInfiniteScroll('activity');
        this.getBattles();
    }

    public async refresh(force?: boolean) {
        if (!this.currentCriteria.value) return;
        this.currentCriteria.value.skip = 0;
        delete this.currentCriteria.value.hasNoMore;
        delete this.results[this.currentCriteria.value.filter.key];
        this.getBattles(force);
    }

    // public activity: { [filter: string]: BattleActivityModel[] } = {};
    // public battles: { [filter: string]: BattleModel[] } = {};
    public async getBattles(force?: boolean, $event?) { // console.log(criteria);
        await this.startLoading('partial');
        this.alerts.reset();

        const criteria = this.currentCriteria.value;
        if (!this.results[criteria.label]) {
            // this.battles[criteria.filter.key] = new GroupedArray({
            //     getKey: battle => +format((battle.created || new Date()), 'yyyyMMdd'),
            //     getLabel: battle => format((battle.created || new Date()), 'MMM do')
            // });
        } else {
            criteria.skip += criteria.take;
        }

        if (criteria.hasNoMore)
            console.log(criteria.label + ' has all results');
        else
            await this.currentCriteria.value.fetch(force)
            .then(response => {
                if (!criteria.skip)
                    this.results[criteria.label] = [];
                criteria.hasNoMore = !response.length;
                this.results[criteria.label].push(...response);
            })
            .catch(error => this.alerts.set('error', error));
        setTimeout(() => {
            this.document.dispatchEvent(new Event('resize'));
            // this.slideTabs.updateAutoHeight();
        }, 1000);
        this.stopLoading('partial');
        if ($event) $event.target.complete();
    }




    public buildLabel = () => {
        this.buttonLabel = this.criterias[this.slideTab].label;
    }

    public statusOptions: BattleStatus[] = [
        BattleStatuses.upcoming,
        BattleStatuses.inProgress,
        BattleStatuses.finished
    ];

    public responseOptions: BattleEntrantResponse[] = [
        BattleEntrantResponses.none,
        BattleEntrantResponses.accept,
        BattleEntrantResponses.decline
    ];
}
