import { Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavController, LoadingController, PopoverController, IonSlides } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';
import { get } from 'lodash-es';

import { BasePage } from 'src/app/_shared/components/base.page';
import { animateList, expandInOut } from 'src/app/_shared/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { BattleService } from '../battle.service';
import { BattleModel } from '../models/battle.model';
import { BattleCriteriaModel } from '../models/battle-criteria.model';
import { GroupedArray } from 'src/app/_shared/models/grouped-array';


/* @Component({
    selector: 'battle-requests',
    templateUrl: 'battle-requests.page.html',
    styleUrls: ['battle-requests.page.scss'],
    animations: [animateList()]
}) */
export class BattleListPage extends BasePage {
    constructor(
        // @Inject(DOCUMENT) public document: Document,
        // public navController: NavController,
        public loadingController: LoadingController,
        public authService: AuthService,
        public battleService: BattleService
    ) { super(loadingController); }

    public groupProperty: string;

    public currentCriteria: BehaviorSubject<BattleCriteriaModel> = new BehaviorSubject(null);

    public results: { [filter: string]: GroupedArray<BattleModel> } = {};

    ionViewWillEnter() {
        this.currentCriteria.pipe(takeUntil(this._onDestroy))
        .subscribe(() => this.refresh(null, false));
    }

    public async refresh($event?, force?: boolean) {
        if (!this.currentCriteria.value) return;
        this.currentCriteria.value.skip = 0;
        delete this.currentCriteria.value.hasNoMore;
        delete this.results[this.currentCriteria.value.filter.key];
        await this.getBattles(force);
        if ($event) setTimeout(() => $event.target.complete(), 100);
    }

    public async getBattles(force?: boolean, $event?) {
        await this.startLoading('partial');
        this.alerts.reset();

        const criteria = this.currentCriteria.value;
        if (!this.results[criteria.filter.key]) {
            // this.battles[criteria.filter.key] = new GroupedArray({
            //     getKey: battle => +format((battle.created || new Date()), 'yyyyMMdd'),
            //     getLabel: battle => format((battle.created || new Date()), 'MMM do')
            // });
        } else {
            criteria.skip += criteria.take;
        }

        if (criteria.hasNoMore)
        // if (get(this.results[criteria.filter.key], 'hasNoMore'))
            console.log(criteria.filter.key + ' has all results');
        else
            await this.battleService.getBattles(criteria, force)
            .then(response => {
                if (!criteria.skip) {
                    // delete this.battles[this.currentCriteria.value.filter.key];
                    this.results[criteria.filter.key] = new GroupedArray({
                        getKey: battle => +format((battle[this.groupProperty] || new Date()), 'yyyyMMdd'),
                        getLabel: battle => format((battle[this.groupProperty] || new Date()), 'MMM do')
                    });
                }
                criteria.hasNoMore = !response.length;
                this.results[criteria.filter.key].merge(response);
            })
            .catch(error => this.alerts.set('error', error));
        this.stopLoading('partial');
        if ($event) $event.target.complete();
    }
}
