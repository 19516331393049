import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleEntrantResponse } from './battle-entrant-responses.dictionary';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleEntrantModel } from './battle-entrant.model';


export class BattleEntrantRequestModel extends BaseModel {
    constructor(data?: BattleEntrantModel) {
        super(data, {
            user: (val: UserModel) => ({ userId: val.userId} as UserModel),
            // user: (val: UserModel) => { data.userId = val.userId; },
            response: (val: BattleEntrantResponse) => val.char
        }); // console.log('BattleEntrantRequestModel', this);
    }

    public userId?: number;
}
