import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { Subscription, Observable } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { get } from 'lodash-es';

import { BaseWalkThruPage } from 'src/app/_shared/components/base-walkthru.page';
import { expandInOut } from 'src/app/_shared/animations';
import { UserService } from '../user.service';
import { BattleCategoryModel } from 'src/app/battles/models/battle-category.model';
import { BattleService } from 'src/app/battles/battle.service';
import { BasePage } from 'src/app/_shared/components/base.page';
import { BattleSuggestionModel } from 'src/app/battles/models/battle-suggestion.model';


@Component({
    selector: 'add-interests',
    templateUrl: 'add-interests.page.html',
    styleUrls: ['add-interests.page.scss'],
    animations: [expandInOut()]
})
export class AddInterestsPage extends BaseWalkThruPage {
    constructor(
        @Inject(DOCUMENT) public document: Document,
        public loadingController: LoadingController,
        private userService: UserService,
        private battleService: BattleService
    ) { super(loadingController); }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        this.getCategories();

        // On swipe back / back button
        this.walkThru.ionSlidePrevEnd.subscribe(async () => {
            this.slides.pop();
        });
    }

    slides: BattleCategoryModel[] = [];

    public search = new class Search {
        constructor(private parent: AddInterestsPage) {}

        results: Observable<BattleCategoryModel[]>;

        getResults($event) { // console.log($event.target.value);
            if (!$event.target.value) {
                delete this.results;
                return;
            }

            this.parent.startLoading('partial');
            this.results = this.parent.battleService.getCategories({
                filter: {
                    query: $event.target.value
                }
            })
            .pipe(catchError(async error => {
                this.parent.alerts.set('error', error);
                return null;
            }));
            this.parent.stopLoading();
        }
    }(this);

    // public categories = new class Categories {
    //     constructor(private parent: AddInterestsPage) {}

        // interests: Observable<BattleSuggestionModel[]>;
        // current: BattleCategoryModel;

        async getCategories() {
            await this.startLoading('full');
            this.slides.push({
                childCategories: await this.battleService.getCategories()
                    .toPromise()
                    .catch(e => this.alerts.set('error', e))
            });
            this.stopLoading('full');
        }

        async showChildCategory(category: BattleCategoryModel) {
            // await this.parent.walkThru.slideTo(0, 1000, true);
            this.startLoading('partial');
            this.slides.push(category);
            await this.walkThru.update();
            await this.walkThru.slideTo(this.slides.length - 1);

            if (get(category.childCategories, 'length')) {
                this.slides[this.slides.length - 1].childCategories = await this.battleService.getCategories({
                    filter: {
                        categoryIds: category.childCategories.map(c => c.battleCategoryId) // battleCategoryId]
                    }
                })
                /* this.current.childCategories = await this.parent.battleService.getBattleSuggestions({
                    filter: {
                        categoryIds: [category.battleCategoryId]
                    }
                }).pipe(catchError(async error => {
                    this.parent.alerts.set('error', error);
                    return null;
                })) */
                .toPromise()
                .catch(e => this.alerts.set('error', e));
            }
            this.stopLoading();
        }
    // }(this);

    public async toggleInterest(interest: BattleCategoryModel) {
        await this.userService.toggleInterest(interest)
        .then(response => { // console.log(response);
            interest.isFollowing = !interest.isFollowing;
        });
    }

    public async validate() {
        return this.currentSlide < this.slides.length - 1;
        /* switch (this.currentSlide) {
            case 0:
                return !!this.categories.current;
        } */
    }

    /* public async back($event: MouseEvent) {
        if (this.currentSlide === 0) return;
        this.slides.pop();
        super.back($event);
    } */
}
