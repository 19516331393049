import { Dictionary, DictionaryDefinition } from '../../_shared/models/_dictionary.model';


export class BattleStatus extends DictionaryDefinition {
    constructor(data?: string) {
        super(data, BattleStatuses);
    }
}

export const BattleStatuses: Dictionary<BattleStatus> = {
    upcoming: {
        key: 'upcoming',
        char: 'U',
        label: 'Upcoming',
        index: 0
        // icon: 'fa-icon'
    },
    cancelled: {
        key: 'cancelled',
        char: 'C',
        label: 'Cancelled',
        index: 1
    },
    noResponse: {
        key: 'noResponse',
        char: 'N',
        label: 'No Response',
        index: 2
    },
    inProgress: {
        key: 'inProgress',
        char: 'I',
        label: 'In Progress',
        index: 3
    },
    finished: {
        key: 'finished',
        char: 'F',
        label: 'Finished',
        index: 4
    }
};
