import { OnDestroy, ViewChild, ContentChild, ViewChildren, QueryList, ElementRef, OnInit } from '@angular/core';
import { IonInfiniteScroll, LoadingController } from '@ionic/angular';
import { ReplaySubject, Subject } from 'rxjs';
import { get } from 'lodash-es';
import { AlertsComponent } from './alerts/alerts.component';
import { Alerts } from './alerts/alerts';
import { BlockUI, BlockUIComponent, BlockUIService, NgBlockUI } from 'ng-block-ui';
import { BlockUIInstanceService } from 'ng-block-ui/services/block-ui-instance.service';


export class BaseComponent implements OnInit, OnDestroy {
    constructor(
        public loadingController: LoadingController
    ) { }

    public alerts: Alerts = new Alerts();

    public _onDestroy: Subject<void>; // = new ReplaySubject();

    ngOnInit() { console.log('ngOnInit', this.constructor.name);
        this._onDestroy = new ReplaySubject();
    }
    ngOnDestroy() { console.log('ngOnDestroy', this.constructor.name);
        if (this._onDestroy) {
            this._onDestroy.next();
            this._onDestroy.complete();
        }
    }

    public loading: {
        [key: string]: {
            // type: 'full' | 'partial' | string,
            count: number,
            element?: HTMLIonLoadingElement | BlockUIComponent
        }
    } = {};

    // public loading: HTMLIonLoadingElement;
    @ViewChildren(BlockUIComponent) blockUIs: QueryList<BlockUIComponent>;
    // @ViewChildren(IonInfiniteScroll) infiniteScrolls: QueryList<IonInfiniteScroll>;
    @ViewChildren(IonInfiniteScroll, { read: ElementRef }) infiniteScrollEls: QueryList<ElementRef>;

    public async triggerInfiniteScroll(name: string) {
        // const activityInfiniteScroll = this.infiniteScrolls.find(el => get(el, 'el').getAttribute('name') === name);
        const activityInfiniteScrollEl = this.infiniteScrollEls.find(el => el.nativeElement.getAttribute('name') === name);
        const scrollEl: HTMLElement = await activityInfiniteScrollEl.nativeElement.closest('ion-content').getScrollElement();
        // setTimeout(() => {
            scrollEl.scroll(0, scrollEl.scrollTop ? 0 : 1); // Math.random() * 100); // .dispatchEvent(new Event('scroll'));
            scrollEl.dispatchEvent(new Event('scroll'));
        // }, 500)
    }

    public async startLoading(type: 'full' | 'partial' | string = 'partial') {
        this.loading[type] = {
            count: (+get(this.loading[type], 'count') || 0) + 1
        };
        if (type === 'full') {
            // Blocks entire page
            // this.renderer.addClass(this.document.body, 'blurred');
            if (!get(this.loading.full, 'element'))
                this.loading.full.element = await this.loadingController.create({
                // duration: 5000,
                // message: 'Please wait...',
                // translucent: true,
                cssClass: 'transparent'
            }); // console.log(this.loading);
            if (get(this.loading.full, 'element')) // magic
                await (this.loading.full.element as HTMLIonLoadingElement).present();
        } else if (type === 'partial') {
            // Toggles ion-header > ion-progress-bar
        } else {
            const blockUI: BlockUIInstanceService = get(this.blockUIs.find(blockUI => blockUI.name === type), 'blockUI');
            if (!blockUI.blockUIInstances[type]) blockUI.decorate(type);
            blockUI.blockUIInstances[type].start(); // console.log(blockUI);
        }
        return this.loading;
    }

    public async stopLoading(type: 'full' | 'partial' | string = 'partial') { // loadingElement: HTMLIonLoadingElement = this.loading.element) {
        const loading = this.loading[type];
        if (!loading || !loading.count) return;
        loading.count = (+get(this.loading[type], 'count') || 1) - 1;

        if (type === 'full') {
            await (this.loading.full.element as HTMLIonLoadingElement).dismiss();
        } else if (type !== 'partial') {
            const blockUI = get(this.blockUIs.find(blockUI => blockUI.name === type), 'blockUI');
            blockUI.blockUIInstances[type].stop();
        }
        if (!loading.count) {
            delete this.loading[type];
        }
        // this.renderer.removeClass(this.document.body, 'blurred');
    }

    public trackByFn(index, item) { // console.log('trackByFn', arguments);
        return index;
    }

    public log($event) { console.log($event); }
}
