import { Dictionary, DictionaryDefinition } from '../../_shared/models/_dictionary.model';


export class BattleScoreType extends DictionaryDefinition {
    constructor(data?: string) {
        super(data, BattleScoreTypes);
    }
}

export const BattleScoreTypes: Dictionary<BattleScoreType> = {
    none: {
        key: 'none',
        char: 'N',
        label: 'None',
        // index: 0
    },
    points: {
        key: 'points',
        char: 'P',
        label: 'Points',
        // index: 1
    },
    timed: {
        key: 'timed',
        char: 'T',
        label: 'Timed',
        // index: 2
    }
};
