import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { LoadingController, ToastController, NavController, IonInfiniteScroll } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { IonSlides } from '@ionic/angular';
import { get } from 'lodash-es';
import { addMinutes, addMilliseconds } from 'date-fns/esm';
import * as assign from 'assign-deep';

import { AuthService } from 'src/app/auth/auth.service';
import { HttpService } from 'src/app/_shared/services/http.service';
import { SocketService } from 'src/app/_shared/services/socket.service';
import { BasePage } from 'src/app/_shared/components/base.page';
import { BattleModel } from '../models/battle.model';
import { BattleService } from '../battle.service';
import { Dictionary } from 'src/app/_shared/models/_dictionary.model';
import { BattleStatuses, BattleStatus } from '../models/battle-statuses.dictionary';
import { BattleEntrantResponse, BattleEntrantResponses } from '../models/battle-entrant-responses.dictionary';
import { BattleEntrantModel } from '../models/battle-entrant.model';
import { BattleRoundStatus, BattleRoundStatuses } from '../models/battle-round-statuses.dictionary';
import { BattleRoundModel } from '../models/battle-round.model';
import { expandInOut } from 'src/app/_shared/animations';
import { UserModel } from 'src/app/users/models/user.model';
import { BaseWalkThruPage } from 'src/app/_shared/components/base-walkthru.page';
import { BattleActivityType, BattleActivityTypes } from '../models/battle-activity-types.dictionary';
import { BattlePrivacies, BattlePrivacy } from '../models/battle-privacy.dictionary';
import { BattleScoreType, BattleScoreTypes } from '../models/battle-score-type.dictionary';
import { BattleActivityCriteriaModel } from '../models/battle-activity-criteria.model';
import { BattleCommentCriteriaModel } from '../models/battle-comment-criteria.model';
import { BattleLikeCriteriaModel } from '../models/battle-like-criteria.model';
import { BlockUI } from 'ng-block-ui';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'battle-detail',
    templateUrl: 'battle-detail.page.html',
    styleUrls: ['battle-detail.page.scss'],
    animations: [expandInOut()]
})
export class BattleDetailPage extends BaseWalkThruPage {
    constructor(
        @Inject(DOCUMENT) public document: Document,
        public route: ActivatedRoute,
        public domSanitizer: DomSanitizer,
        public loadingController: LoadingController,
        public navController: NavController,
        public toastController: ToastController,
        public authService: AuthService,
        private socketService: SocketService,
        private battleService: BattleService
    ) { super(loadingController); }

    public battle: BattleModel; // = new BattleModel();
    public currentEntrant: BattleEntrantModel;
    public battleStatuses: Dictionary<BattleStatus> = BattleStatuses;
    public battleResponses: Dictionary<BattleEntrantResponse> = BattleEntrantResponses;
    public battlePrivacies: Dictionary<BattlePrivacy> = BattlePrivacies;
    public battleScoreTypes: Dictionary<BattleScoreType> = BattleScoreTypes;
    public roundStatuses: Dictionary<BattleRoundStatus> = BattleRoundStatuses;
    public activityTypes: Dictionary<BattleActivityType> = BattleActivityTypes;

    public crowd: any[];

    // private battleHub: signalR.HubConnection;
    // public subscription: Subscription;

    async ionViewWillEnter() { console.log('ionViewWillEnter:', this.constructor.name);
        super.ngOnInit();
        this.route.params
        .pipe(finalize(() => {
            console.log(this.constructor.name, 'routeParams.finalize');
        }))
        .pipe(takeUntil(this._onDestroy))
        .subscribe(async (params) => { console.log('route:', params);
            // if (this.battle) this.walkThru.slideTo(0);
            await this.join();
            this.activity.init();
            this.page2.init();
        });

        this.walkThru.ionSlideDidChange.pipe(takeUntil(this._onDestroy))
        .subscribe(async () => {
            this.currentSlide = await this.walkThru.getActiveIndex();
            if (this.currentSlide === 1) {
                if (!this.battle.likes) this.likes.load();
            }
        });
    }

    ionViewWillLeave() { // console.log('unsubscribe');
        this.leave();
        super.ngOnDestroy();
    }

    private async join() { console.log('join', this.socketService.connectionId); // this.authService.me
        await this.startLoading('full');

        this.socketService.hub.on('battle', (data: BattleModel) => { // console.log('battle', data);
            this.updateBattle(new BattleModel(data));
        });
        this.socketService.hub.on('endRound', (data) => { // console.log('roundEnd', data);
            this.rounds.end(false);
        });
        this.socketService.hub.on('endBattle', (data: BattleModel) => { // console.log('roundEnd', data);
            this.updateBattle(new BattleModel(data));
            this.toastController.create({
                message: 'The Battle has ended',
                duration: 5000
            }).then(toast => toast.present());
        });
        this.socketService.hub.on('crowd', (users) => { // console.log('crowd', data);
            this.crowd = users.map(user => new UserModel(user));
        });
        this.socketService.hub.on('friendJoinedCrowd', (data: UserModel) => { console.log('friendJoinedCrowd', data);
            this.toastController.create({
                message: data.name + ' has joined the crowd',
                duration: 5000
            }).then(toast => toast.present());
        });
        this.socketService.hub.on('friendLeftCrowd', (data) => { console.log('friendLeftCrowd', data); });

        await this.battleService.join(+this.route.snapshot.params.battleId)
            .then(response => this.updateBattle(response))
            .catch(e => this.alerts.set('error', e));
        // this.rounds.init();
        await this.stopLoading('full');
        // console.log(this.battle);
    }

    private updateBattle(battle: BattleModel) { console.log('updateBattle', battle);
        // Sanitize stream urls
        /* (battle.streams || []).forEach(stream => {
            stream.url = this.domSanitizer.bypassSecurityTrustResourceUrl(stream.url as string);
        }); */

        // Set / Patch Battle
        if (!this.battle)
            this.battle = battle;
        else {
            // Arrays are replaced - update data not provided
            if (battle.entrants) battle.entrants.forEach(entrant => {
                const existingEntrant = (this.battle.entrants || []).find(e => e.entrantId === entrant.entrantId);
                if (!existingEntrant) return;
                if (!get(entrant.roundScores, 'length'))
                    entrant.roundScores = existingEntrant.roundScores;
                if (!entrant.totalScore)
                    entrant.totalScore = existingEntrant.totalScore;
                if (entrant.votes === undefined)
                    entrant.votes = existingEntrant.votes;
            });
            // Append only new
            if (battle.activity)
                battle.activity = battle.activity.concat(this.battle.activity || []);
            if (battle.comments)
                battle.comments = battle.comments.concat(this.battle.comments || []);
            assign(this.battle, battle);
        }

        this.rounds.setCurrentRound();
        this.currentEntrant = (() => {
            let currentEntrant = (this.battle.entrants || []).find((entrant: BattleEntrantModel) => entrant.user.userId === this.authService.user.userId);
            if (!currentEntrant && this.battle.isOpenInvite)
                currentEntrant = {
                    user: this.authService.user,
                    response: BattleEntrantResponses.none
                } as BattleEntrantModel;
            return currentEntrant;
        })();
        setTimeout(() => this.page2Tabs.updateAutoHeight(), 500);
    }

    private async leave() {
        // Ensure streams stop
        (this.battle.streams || []).forEach(stream => {
            delete stream.url;
        });

        // Stop UI listening over socket
        this.socketService.hub.off('battle');
        this.socketService.hub.off('endRound');
        this.socketService.hub.off('endBattle');
        this.socketService.hub.off('crowd');
        this.socketService.hub.off('friendJoinedCrowd');
        this.socketService.hub.off('friendLeftCrowd');

        // Stop API broadcasting over socket
        return this.battleService.leave(+this.route.snapshot.params.battleId)
        .then((response) => { console.log('left');
            delete this.battle;
        });
    }

    public async refresh($event?, force?: boolean) {
        await this.startLoading('full');
        delete this.battle;
        await this.battleService.getBattle(+this.route.snapshot.params.battleId, force)
            .then(response => this.updateBattle(response))
            .catch(e => this.alerts.set('error', e));
        setTimeout(() => { // Allow infinite-scrolls to render/reset
            this.activity.init();
            this.page2.init();
        });
        this.stopLoading('full');

        // await this.activity.init();
        // await this.comments.init();
        // await this.likes.init();

        if ($event) setTimeout(() => $event.target.complete(), 100);
    }

    // Like
    public async like() {
        await this.startLoading('partial');
        await this.battleService.like(this.battle.battleId, this.battle.security.isLiked)
        .catch(error => {
            this.toastController.create({
                message: 'There was an problem liking the Challenge',
                duration: 5000
            }).then(toast => toast.present());
        });
        await this.stopLoading();
    }

    // Upcoming
    public async respond(response?: BattleEntrantResponse) {
        await this.startLoading('partial');
        await this.battleService.respond(this.battle.battleId, response)
        .catch(error => {
            this.toastController.create({
                message: 'There was an problem responding',
                duration: 5000
            }).then(toast => toast.present());
        });
        await this.stopLoading();
    }

    // Begin
    public async begin() {
        await this.startLoading('partial');
        await this.battleService.begin(this.battle.battleId)
        .catch(error => {
            this.toastController.create({
                message: 'There was an problem beginning the battle',
                duration: 5000
            }).then(toast => toast.present());
        });
        await this.stopLoading();
    }

    // Rounds
    @ViewChildren('roundSlider') set content(content: QueryList<IonSlides>) {
        setTimeout(() => { // console.log(content);
            this.rounds.init(content.first); // Allows viewChild to work inside *ngIf
        });
    }

    public rounds = new class Rounds {
        constructor(private parent: BattleDetailPage) {}

        public slider: IonSlides;

        public current: {
            visibleSlide?: number,
            round?: BattleRoundModel,
            hasStarted?: boolean,
            endTarget?: Date
        } = {
            visibleSlide: 0
        };

        public init(slider: IonSlides) {
            if (!slider) return;
            this.slider = slider;
            // if (this.parent.battle.status.index < BattleStatuses.inProgress.index) return;

            this.slider.ionSlideWillChange.pipe(takeUntil(this.parent._onDestroy)).subscribe(async () => {
                this.current.visibleSlide = await this.slider.getActiveIndex();
            });
            this.setCurrentRound();
        }

        public setCurrentRound() {
            if (!this.parent.battle.rounds) return;
            if (this.parent.battle.status.key !== BattleStatuses.inProgress.key) return;
            if (this.parent.battle.scoreType.key === BattleScoreTypes.none.key) return;

            this.current.round = this.parent.battle.rounds[this.parent.battle.rounds.length - 1]; console.log('setCurrent', this.parent.battle.rounds.length); // , this.current.round);

            if (get(this.current.round, 'startedAt')) {
                // Calc "Starts In..."
                this.current.hasStarted = (() => {
                    const startsIn = this.current.round.startedAt.getTime() - new Date().getTime();
                    if (startsIn > 0) {
                        setTimeout(() => {
                            this.current.hasStarted = true;
                        }, startsIn);
                        return false;
                    } else return true;
                })();

                // Only if inProgress or stopped
                if (this.current.round.status.index < BattleRoundStatuses.finished.index) {
                    // Calc end time
                    this.current.endTarget = addMinutes(this.current.round.startedAt, this.parent.battle.roundMins);
                    this.current.endTarget = addMilliseconds(this.current.endTarget, this.current.round.stoppageTime || 0);

                    // Calc current stoppage
                    if (this.current.round.status.key === BattleRoundStatuses.stopped.key) {
                        const pausedFor = new Date().getTime() - this.current.round.pausedAt.getTime();
                        // const subMs = subMilliseconds; console.log(pausedFor / 60 / 1000);
                        this.current.endTarget = addMilliseconds(this.current.endTarget, pausedFor);
                    }
                }
            } else {
                delete this.current.hasStarted;
                delete this.current.endTarget;
            }

            setTimeout(() => {
    //            if (this.slider) this.slider.slideTo(this.parent.battle.rounds.length);
            });
        }

        public async prev() {
            this.slider.slideTo(await this.slider.getActiveIndex() - 1);
        }

        public async next() {
            const target = await this.slider.getActiveIndex() + 1;
            if (target >= this.parent.battle.rounds.length)
                await this.create(); // parent.battle.rounds.push({});
            this.slider.slideTo(target);
        }

        private async create() {
            await this.parent.startLoading('partial');
            await this.parent.battleService.createRound(this.parent.battle.battleId)
            // .then((response) => { console.log(response); })
            .catch((error) => {
                this.parent.toastController.create({
                    message: 'There was an problem creating the round',
                    duration: 2000
                }).then(toast => toast.present());
            });
            setTimeout(() => { // Allow webSocket to send out new round
                this.slider.slideTo(this.parent.battle.rounds.length);
            }, 100);
            await this.parent.stopLoading();
        }

        public async start() {
            await this.parent.startLoading('partial');
            await this.parent.battleService.startRound(this.parent.battle.battleId, this.parent.battle.rounds[this.parent.battle.rounds.length - 1].roundId)
            .catch((error) => {
                this.parent.toastController.create({
                    message: 'There was an problem starting the round',
                    duration: 5000
                }).then(toast => toast.present());
            });
            await this.parent.stopLoading();
        }

        public toggleIsRunning() {
            switch (this.current.round.status.key) {
                case BattleRoundStatuses.inProgress.key:
                    return this.pause();
                case BattleRoundStatuses.stopped.key:
                    return this.resume();
            }
        }

        private async pause() {
            await this.parent.startLoading('partial');
            await this.parent.battleService.pauseRound(this.parent.battle.battleId, this.parent.battle.rounds[this.parent.battle.rounds.length - 1].roundId)
            .catch((error) => {
                this.parent.toastController.create({
                    message: 'There was an problem pausing the round',
                    duration: 5000
                }).then(toast => toast.present());
            });
            await this.parent.stopLoading();
        }

        private async resume() {
            await this.parent.startLoading('partial');
            await this.parent.battleService.resumeRound(this.parent.battle.battleId, this.parent.battle.rounds[this.parent.battle.rounds.length - 1].roundId)
            .catch((error) => {
                this.parent.toastController.create({
                    message: 'There was an problem resuming the round',
                    duration: 5000
                }).then(toast => toast.present());
            });
            await this.parent.stopLoading();
        }

        public async end(isManual: boolean) { console.log('end round');
            this.current.round.status = BattleRoundStatuses.finished;
            if (isManual) {
                await this.parent.battleService.endRound(this.parent.battle.battleId, this.parent.battle.rounds[this.parent.battle.rounds.length - 1].roundId)
                .catch((error) => {
                    this.parent.toastController.create({
                        message: 'There was an problem ending the round',
                        duration: 5000
                    }).then(toast => toast.present());
                });
            }
            this.parent.toastController.create({
                message: 'Round Ended',
                duration: 5000
            }).then(toast => toast.present());
        }

        /* public async slidePrev(): Promise<void> {
            await this.slider.slidePrev();
        }

        public async slideNext(): Promise<void> {
            await this.slider.slideNext();
        }

        public async getActiveIndex(): Promise<number> {
            return this.slider.getActiveIndex();
        } */
    }(this);

    // InProgress
    public async score(val, entrant, roundId) {
        await this.startLoading('partial');
        await this.battleService.score(this.battle.battleId, roundId, entrant.entrantId, val)
        .catch((error) => {
            this.toastController.create({
                message: 'There was an problem updating the score',
                duration: 5000
            }).then(toast => toast.present());
        });
        await this.stopLoading();
    }

    public async vote(entrant) {
        if (this.battle.currentVote === entrant.entrantId) return;
        await this.startLoading('partial');
        /* if (this.battle.currentVote) { // As
            --this.battle.entrants.find(e => e.entrantId === this.battle.currentVote).votes;
        } */
        await this.battleService.vote(this.battle.battleId, entrant.entrantId)
        .catch((error) => {
            this.toastController.create({
                message: 'There was an problem voting',
                duration: 5000
            }).then(toast => toast.present());
        });
        await this.stopLoading();
    }

    // Activity
    public activity = new class Activity {
        constructor(private parent: BattleDetailPage) {}

        public hasNoMore: boolean;

        async init() { console.log('activity.init');
            delete this.hasNoMore;
            this.parent.triggerInfiniteScroll('activity');
        }

        async load($event?) { console.log('activity.load');
            await this.parent.startLoading('partial'); // activity');
            if (this.hasNoMore)
                console.log('Activity has all results');
            else
                await this.parent.battleService.getActivity(new BattleActivityCriteriaModel({
                filter: {
                    battleId: +this.parent.route.snapshot.params.battleId
                },
                skip: get(this.parent.battle, 'activity.length')
            }), true) // get(this.parent.battle, 'activity.length') > 1)
            .then(response => { // console.log(response);
                if (!this.parent.battle.activity) this.parent.battle.activity = [];
                this.hasNoMore = !response.length;
                this.parent.battle.activity.push(...response);
                /* this.parent.updateBattle({
                    activity: response
                }); */
            })
            .catch(e => this.parent.alerts.set('error', e));
            await this.parent.stopLoading('partial'); // activity');
            setTimeout(() => this.parent.walkThru.updateAutoHeight(), 300);
            if ($event) $event.target.complete();
        }
    }(this);

    // Comments
    public comments = new class Comments {
        constructor(private parent: BattleDetailPage) {}

        // async init() {
        //     this.parent.triggerInfiniteScroll('comments');
        // }

        async load($event?) { // refresh: boolean) {
            this.parent.startLoading('partial'); // comments');
            await this.parent.battleService.getComments(new BattleCommentCriteriaModel({
                filter: {
                    battleId: +this.parent.route.snapshot.params.battleId
                },
                skip: get(this.parent.battle, 'comments.length')
            }), true) // get(this.parent.battle, 'comments.length') > 1)
            .then(response => { // console.log(response);
                if (!this.parent.battle.comments) this.parent.battle.comments = [];
                this.parent.battle.comments.push(...response);
                /* this.parent.updateBattle({
                    comments: response
                }); */
            })
            .catch(e => this.parent.alerts.set('error', e));
            this.parent.stopLoading('partial'); // comments');
            setTimeout(() => this.parent.page2Tabs.updateAutoHeight(), 300);
            if ($event) $event.target.complete();
        }
    }(this);

    // Likes
    public likes = new class Likes {
        constructor(private parent: BattleDetailPage) {}

        // async init() {
        //     this.parent.triggerInfiniteScroll('likes');
        // }

        async load($event?) { // refresh: boolean) {
            this.parent.startLoading('partial'); // likes');
            await this.parent.battleService.getLikes(new BattleLikeCriteriaModel({
                filter: {
                    battleId: +this.parent.route.snapshot.params.battleId
                },
                skip: get(this.parent.battle, 'likes.length')
            }), true) // get(this.parent.battle, 'likes.length') > 1)
            .then(response => { // console.log(response);
                if (!this.parent.battle.likes) this.parent.battle.likes = [];
                this.parent.battle.likes.push(...response);
                /* this.parent.updateBattle({
                    likes: response
                }); */
            })
            .catch(e => this.parent.alerts.set('error', e));
            this.parent.stopLoading('partial'); // likes');
            setTimeout(() => this.parent.page2Tabs.updateAutoHeight(), 300);
            if ($event) $event.target.complete();
        }
    }(this);

    // Page 2
    @ViewChild('page2Tabs', { static: false }) public page2Tabs: IonSlides;
    public page2 = new class Page2 {
        constructor(private parent: BattleDetailPage) {}

        public filters = [
            { label: 'Comments' },
            { label: 'Likes' }
        ];

        // public filter = { label: 'Interests' };

        public tabIndex: number = 0;

        async init() {
            this.parent.triggerInfiniteScroll('page2');
        }

        async load($event?) {
            if (!this.tabIndex) {
                await this.parent.comments.load();
            } else if (this.tabIndex === 1) {
                await this.parent.likes.load();
            }
            if ($event) $event.target.complete();
        }

        public select(tabIndex: number) {
            // this.filter = this.filters[tabIndex];
            this.tabIndex = tabIndex;
            this.parent.walkThru.slideTo(1);
            this.parent.page2Tabs.slideTo(tabIndex);
            // this.showMenu = false;
        }

        public newComment: string;

        public async addComment() { console.log('addComment', this.newComment);
            await this.parent.battleService.comment(this.parent.battle.battleId, this.newComment);
            delete this.newComment;
        }
    }(this);
}
