import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LoadingController } from '@ionic/angular';

import { BasePage } from '../_shared/components/base.page';
import { AuthService } from '../auth/auth.service';
import { BattleService } from '../battles/battle.service';
import { BattleModel } from '../battles/models/battle.model';
import { BattleFilterModel } from '../battles/models/battle-filter.model';
import { BattleStatuses } from '../battles/models/battle-statuses.dictionary';
import { BattleCategoryModel } from '../battles/models/battle-category.model';
import { UserService } from '../users/user.service';
import { BattleActivityModel } from '../battles/models/battle-activity.model';
import { BattleActivityFilterModel } from '../battles/models/battle-activity-filter.model';
import { BattleActivityCriteriaModel } from '../battles/models/battle-activity-criteria.model';

@Component({
    selector: 'app-home',
    templateUrl: 'home.page.html',
    styleUrls: ['home.page.scss'],
})
export class HomePage extends BasePage {
    constructor(
        @Inject(DOCUMENT) public document: Document,
        public loadingController: LoadingController,
        public authService: AuthService,
        public userService: UserService,
        // private socketService: SocketService,
        private battleService: BattleService
    ) { super(loadingController); }

    async ionViewWillEnter() {
        super.ngOnInit();
        this.inProgress.refresh();
        this.stream.init();
    }

    public async refresh($event?) {
        this.stream.criteria.skip = 0;
        delete this.stream.criteria.hasNoMore;
        delete this.stream.results;
        await this.triggerInfiniteScroll('stream');
        if ($event) setTimeout(() => $event.target.complete(), 100);
    }

    public inProgress = new class InProgress {
        constructor(private parent: HomePage) { }

        public battles: BattleModel[];

        public async refresh() {
            await this.parent.startLoading('partial');
            this.parent.battleService.getBattles({
                filter: new BattleFilterModel({
                    // key: 'happeningNow',
                    statuses: [BattleStatuses.inProgress],
                    entrants: [this.parent.authService.user]
                }),
                order: [{
                    key: 'When',
                    descending: true
                }],
                take: 1
            })
            .then(response => { console.log(response);
                this.battles = response;
            });
            this.parent.stopLoading();
        }
    }(this);

    public stream = new class Stream {
        constructor(private parent: HomePage) { }

        public results: BattleActivityModel[];

        public criteria: BattleActivityCriteriaModel = new BattleActivityCriteriaModel({
            filter: new BattleActivityFilterModel({
                key: 'stream',
                /* users: (() => {
                    const users = [{ userId: +this.parent.authService.user.userId }];
                    // if (users[0].userId !== this.authService.user.userId)
                    //     users.push({ userId: this.authService.user.userId });
                    return users;
                })(), */
                statuses: [],
                responses: []
            }),
            selection: {
                isDetailed: true
            },
        });

        async init() { // console.log('activity.init');
            delete this.criteria.hasNoMore;
            this.parent.triggerInfiniteScroll('stream');
        }

        public async load($event?, force?: boolean) {
            await this.parent.startLoading('partial');

            if (!this.results) {
                force = true;
            } else if (this.results) {
                this.criteria.skip += this.criteria.take;
            }

            if (this.criteria.hasNoMore)
                console.log('Stream has all results');
            else
                await this.parent.battleService.getActivity(this.criteria, force)
                .then(response => { console.log(response);
                    if (!this.criteria.skip)
                        this.results = [];
                    this.criteria.hasNoMore = !response.length;
                    this.results.push(...response);
                })
                .catch(error => this.parent.alerts.set('error', error));
            await this.parent.stopLoading('partial'); // activity');
            if ($event) $event.target.complete();
        }
    }(this);

    /* public interests = new class Interests {
        constructor(private parent: HomePage) { }

        public categories: BattleCategoryModel[];

        public async init() {
            await this.parent.userService.getInterests()
            .then(response => { console.log(response);
                // this.categories = response;
            });

            await this.parent.userService.getCategories()
            .then(response => { // console.log(response);
                this.categories = response;
            });
        }

        public async add(genre: BattleCategoryModel) {
            await this.parent.battleService.addGenre(genre)
            .then(response => { console.log(response);
            });
        }

        public toggle(interest) {
        }
    }(this); */
}
