import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleStatus } from './battle-statuses.dictionary';
import { BattleEntrantModel } from './battle-entrant.model';
import { BattleSecurityModel } from './battle-security.model';
import { BattleRoundModel } from './battle-round.model';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleScoreOrder } from './battle-score-order.dictionary';
import { BattleScoreType } from './battle-score-type.dictionary';


export class BattleSuggestionModel extends BaseModel {
    constructor(data?: BattleSuggestionModel, dictionary: any = {}) {
        super(data, Object.assign({
            status: BattleStatus,
            owner: UserModel,
            scoreType: BattleScoreType,
            scoreOrder: BattleScoreOrder,
        }, dictionary)); // console.log('BattleSuggestionModel', this);
    }

    public battleSuggestionId?: number;
    public hasCrowdVoting?: boolean;
    public icon?: string;
    public isFollowing?: boolean;
    public isOpenInvite?: boolean;
    public what?: string;
    public how?: string;
    public status?: BattleStatus;
    public scoreType?: BattleScoreType;
    public scoreOrder?: BattleScoreOrder;
    public totalRounds?: number;
    public currentRound?: number;
    public roundMins?: number;
}
