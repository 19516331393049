import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ToastController } from '@ionic/angular';
import { HubConnection, HubConnectionState, HubConnectionBuilder } from '@aspnet/signalr';
import { HttpService } from './http.service';
import { AppConfig } from '../models/app-config.model';


@Injectable({
    providedIn: 'root'
})
export class SocketService {
    constructor(
        @Inject(DOCUMENT) public document: Document,
        private appConfig: AppConfig,
        private toastController: ToastController,
        private httpService: HttpService
    ) { }

    public hub: HubConnection;

    public connectionId: string;

    private shouldBeConnected: boolean; // Handle interuptions

    private isConnecting: boolean;

    public async initHub(): Promise<HubConnection> {
        if (!this.hub) { console.log('socket.startingConnection...');
            this.hub = new HubConnectionBuilder()
            .withUrl(this.document.location.protocol + '//' + this.appConfig.api + '/live', {
                accessTokenFactory: () => this.httpService.headers.get('Authorization').replace('Bearer ', '')
            })
            .build();

            this.hub.onclose(async (error) => {
                if (!this.shouldBeConnected || this.isConnecting) return; console.warn(error);
                const toast = await this.toastController.create({
                    message: 'Connection lost, attempting to reconnect...',
                    // duration: 2000
                }); // .then(toast => {
                    toast.present();
                // });
                const tryAgain = () => {
                    setTimeout(async () => {
                        await this.connect();
                        if (this.hub.state === HubConnectionState.Connected) {
                            // this.router.navigated = false;
                            // toast.dismiss();
                            this.document.location.reload();
                            /* const targetUrl = this.router.routerState.snapshot.url;
                            this.router.navigateByUrl('/');
                            setTimeout(() => { this.router.navigateByUrl(targetUrl); }, 100); */
                        } else
                            tryAgain();
                    }, 1000);
                };
                tryAgain();
                /* if (this.hub.state === HubConnectionState.Disconnected)
                    toast.message = 'Connection lost.';
                else
                    toast.dismiss(); */
            });
        }
        return this.hub;
    }

    public async connect(): Promise<HubConnection> { console.log('socketService.connect');
        // return new Promise((resolve, reject) => {
            if (!this.hub) { console.log('socket.noHub');
                throw new Error('No Hub to start');
            } else if (this.hub.state === HubConnectionState.Connected) { console.log('socketService.alreadyConnected');
                return this.hub;
            } else {
                /* this.hub = new HubConnectionBuilder()
                .withUrl('/live', {
                    accessTokenFactory: () => this.httpService.headers.get('Authorization').replace('Bearer ', '')
                })
                .build(); */

                this.shouldBeConnected = true;
                this.isConnecting = true;
                await this.hub.start().catch(error => { console.warn('Error while starting connection: ' + error); })
                .then(async (arg) => { // console.log('socket.connectionStarted.', this.hub, arguments);
                    if (this.hub.state === HubConnectionState.Connected) {
                        this.connectionId = await this.hub.invoke('connectionId'); console.log('socket.connectionStarted:', this.connectionId);
                    }
                });
                delete this.isConnecting;
                // resolve(this.hub); // this.connection.next(this.hub);

               /*  delete this.isConnecting;
                    throw error; // reject(error);
                }); .finally(() => {
                    delete this.isConnecting;
                }); */
            }
            return this.hub;
            /* else if (this.hub.state === HubConnectionState.Disconnected) { console.log('socket.isConnecting...');
                const waitInterval = setInterval(() => {
                    if (!this.isConnecting) { // this.hub.state === HubConnectionState.Connected) {
                        clearInterval(waitInterval);
                        this.hub ? resolve(this.hub) : reject('Not connecting'); // this.connection.next(this.hub);
                    } else console.log('socket.waiting...');
                }, 250);
            } else { console.log('socket.alreadyConnected.');
                resolve(this.hub); // this.connection.next(this.hub);
            }
        }); */
    }

    public async disconnect() {
        delete this.shouldBeConnected;
        this.hub.stop();
    }
}
