import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule, Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { MatButtonModule, MatDialogModule, MatTabsModule, MatIconModule, MAT_DATEPICKER_SCROLL_STRATEGY, MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY, DateAdapter, MatNativeDateModule } from '@angular/material';

import { MatDatetimepicker, MatDatetimepickerContent } from './datetimepicker';
import { MatDatetimepickerCalendar } from './calendar';
import { MatDatetimepickerCalendarBody } from './calendar-body';
import { MatDatetimepickerClock } from './clock';
import { MatDatetimepickerInput } from './datetimepicker-input';
import { MatDatetimepickerToggle } from './datetimepicker-toggle';
import { MatDatetimepickerMonthView } from './month-view';
import { MatDatetimepickerYearView } from './year-view';
import { MatDatetimeFormats, MAT_DATETIME_FORMATS } from './adapter/datetime-formats';
import { DatetimeAdapter } from './adapter/datetime-adapter';
import { NativeDatetimeAdapter } from './adapter/native-datetime-adapter';
import { MAT_NATIVE_DATETIME_FORMATS } from './adapter/native-datetime-formats';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatNativeDateModule,
    MatTabsModule,
    MatIconModule,
    OverlayModule,
    A11yModule
  ],
  entryComponents: [
    MatDatetimepickerContent
  ],
  declarations: [
    MatDatetimepickerCalendar,
    MatDatetimepickerCalendarBody,
    MatDatetimepickerClock,
    MatDatetimepicker,
    MatDatetimepickerToggle,
    MatDatetimepickerInput,
    MatDatetimepickerContent,
    MatDatetimepickerMonthView,
    MatDatetimepickerYearView
  ],
  providers: [
    {
      provide: DatetimeAdapter,
      useClass: NativeDatetimeAdapter
    }, {
      provide: MAT_DATETIME_FORMATS,
      useValue: MAT_NATIVE_DATETIME_FORMATS
    }, {
      provide: MAT_DATEPICKER_SCROLL_STRATEGY,
      deps: [Overlay],
      useFactory: MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY,
    }
  ],
  exports: [
    MatNativeDateModule,
    // MatButtonModule, MatDialogModule, MatIconModule,
    MatDatetimepickerCalendar,
    MatDatetimepickerCalendarBody,
    MatDatetimepickerClock,
    MatDatetimepicker,
    MatDatetimepickerToggle,
    MatDatetimepickerInput,
    MatDatetimepickerContent,
    MatDatetimepickerMonthView,
    MatDatetimepickerYearView
  ]
})
export class MatDatetimepickerModule {
  static forRoot(
    adapter?: DatetimeAdapter<any>,
    formats: MatDatetimeFormats = {
      parse: {},
      display: {}
    } as MatDatetimeFormats
  ): ModuleWithProviders<MatDatetimepickerModule> {
    return {
        ngModule: MatDatetimepickerModule,
        providers: [
            /* {
              provide: DatetimeAdapter,
              useFactory: (dateAdapter: DateAdapter<any>) => {
                return adapter || dateAdapter
              }
            }, {
              provide: MAT_DATETIME_FORMATS,
              useValue: {
                parse: Object.assign({
                }, formats.parse),
                display: Object.assign({
                  dateInput: {year: 'numeric', month: '2-digit', day: '2-digit'},
                  monthInput: {month: 'long'},
                  datetimeInput: {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'},
                  timeInput: {hour: '2-digit', minute: '2-digit'},
                  monthYearLabel: {year: 'numeric', month: 'short'},
                  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
                  monthYearA11yLabel: {year: 'numeric', month: 'long'},
                  popupHeaderDateLabel: {month: 'short', day: '2-digit'}
                }, formats.display)
              }
            } */
        ]
    };
  }
}
