import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { HubConnection, HubConnectionState } from '@aspnet/signalr';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';

// import { BaseHubService } from './_base-hub.service';
import { SocketService } from '../_shared/services/socket.service';
import { HttpService } from '../_shared/services/http.service';
import { UserModel } from '../users/models/user.model';
import { HttpError } from '../_shared/models/http-error';
import { ToastController } from '@ionic/angular';
import { AuthUserModel } from '../users/models/auth-user.model';


@Injectable({
    providedIn: 'root'
})
export class AuthService { // extends BaseHubService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private storageService: Storage,
        private toastController: ToastController,
        private httpService: HttpService,
        private socketService: SocketService
    ) { // super(document);
        console.log('AuthService', this.document.location);
        // this.facebookCode = this.document.location.search;
    }

    public user: AuthUserModel;

    public redirectUrl: string;

    /* public authState = this.socialAuthService.authState;

    async autoSignIn() { console.log('autoSignIn');
        return new Promise((resolve) => {
            this.authState.subscribe(async (response) => { console.log('authState', response);
                await this.login(response).catch(error => console.warn(error));
                resolve(!!this.user);
            });
        });
    } */

    public async signInWithFB() {
        const user = await this.httpService.post('/api/auth/login/facebook') // ?provider=facebook')
        .toPromise()
        .then((response: { redirect: string }) => { console.log('auth', response);
            if (response.redirect)
                this.document.location.href = response.redirect; // Will reload page
        });
        // const user = await this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).catch(error => console.log(error));
       // return this.login(user);
    }

    public async login(provider: string, body: string | any): Promise<boolean> {
        let authResponse;
        switch (provider) {
            case 'credentials':
                authResponse = await this.httpService.post({
                    url: '/api/auth/login',
                    body: {
                        provider: provider,
                        code: btoa(JSON.stringify(body))
                    },
                    observe: 'response'
                }).toPromise().catch((error: HttpErrorResponse) => {
                    throw new HttpError(error, 'There was an issue logging in');
                }) as HttpResponse<AuthUserModel>;
                break;
            case 'facebook':
                // Get token
                const facebookReqUrl = await this.httpService.post<{ request: string }>({
                    url: '/api/auth/login/facebook/2',
                    body: {
                        code: body
                    }
                }).toPromise().catch((error: HttpErrorResponse) => {
                    throw new HttpError(error, 'There was an issue with Facebook OAuth API');
                });

                // Get user from FB (must be from client)
                const fbUser = await this.httpService.get({
                    url: facebookReqUrl.request,
                    isExternal: true
                }).toPromise().catch((error: HttpErrorResponse) => {
                    throw new HttpError(error, 'There was an issue with Facebook OAuth User');
                }) as HttpResponse<AuthUserModel>;

                // Send FB user to API
                // TODO: Make this edit user?
                authResponse = await this.httpService.post({
                    url: '/api/auth/login/facebook/3',
                    body: fbUser,
                    observe: 'response'
                }).toPromise().catch((error: HttpErrorResponse) => {
                    throw new HttpError(error, 'There was an issue with Facebook OAuth API');
                }) as HttpResponse<AuthUserModel>;
                break;
            case 'token': // Auto-Login from stored token
                authResponse = await this.httpService.get({
                    url: '/api/auth/currentUser',
                    headers: new HttpHeaders({ Authorization: 'Bearer ' + body }),
                    observe: 'response'
                }).toPromise().catch((error: HttpErrorResponse) => {
                    if (error.status === 401) this.storageService.remove('Authorization');
                    throw new HttpError(error, 'Could not validate Token');
                }) as HttpResponse<AuthUserModel>;
                break;
        }

        return await this.completeLogin(authResponse);
    }

    public async signUp(signUpForm: any) {
        const pass = btoa(signUpForm.pass);
        delete signUpForm.pass;
        const authResponse = await this.httpService.post({
            url: '/api/auth/signUp',
            body: {
                user: signUpForm,
                pass: pass
            },
            observe: 'response'
        }).toPromise().catch((error: HttpErrorResponse) => {
            throw new HttpError(error, error.message || 'There was an issue signing up');
        });
        return await this.completeLogin(authResponse);
    }

    private async completeLogin(authResponse) {
        if (!authResponse) return false;

        // Token
        this.storeToken({
            token: authResponse.headers.get('Authorization'),
            expiresIn: +authResponse.headers.get('ExpiresIn')
        });

        // Socket
        const hub = await this.socketService.initHub();
        hub.on('friendSignedIn', (friend: UserModel) => { console.log('friendJoined', friend);
            this.toastController.create({
                message: friend.name + ' signed in',
                duration: 5000
            }).then(toast => toast.present());
        });
        hub.on('friends', (friends: UserModel[]) => {
            this.friends = friends.map(friend => new UserModel(friend)); console.log('friends', this.friends);
        });
        await this.socketService.connect(); /* .then((hub: HubConnection) => { console.log('AuthService.connected');
            hub.on('friendJoined', (data) => { console.log('friendJoined', data);
            });
            hub.on('friends', (data) => { console.log('friends', data);
                this.friends = data;
            });
        }); */

        this.user = new AuthUserModel(authResponse.body); console.log(this.user);

        return !!this.user;
    }

    private storeToken(jwt) {
        if (!jwt.token) return; console.log('Token expires in', jwt.expiresIn / 60, 'minutes');
        this.storageService.set('Authorization', jwt.token);
        this.httpService.headers = this.httpService.headers.set('Authorization', 'Bearer ' + jwt.token);
    }

    public async signOut() {
        delete this.user;
        this.storageService.remove('Authorization');
        // this.socialAuthService.signOut().catch(error => console.warn(error));
        this.socketService.disconnect(); // this.socketService.hub.stop();
        this.router.navigate(['/login']);
    }

    /* public AUTH_SERVER_ADDRESS: string = 'http://localhost:3000';

    public authSubject: BehaviorSubject<any> = new BehaviorSubject(false);

    public me: any; */

    public friends: UserModel[];

    public async getFriends(): Promise<UserModel[]> {
        return this.httpService.get('/api/auth/friends').toPromise()
        .then((response: UserModel[]) => { console.log('friends', response);
            return response.map(battle => new UserModel(battle));
        });
    }

    public async allowAnonymousChallenges(isAllowed: boolean): Promise<any> {
        return this.httpService.post({
            url: '/api/auth/allowAnonymousChallenges',
            body: isAllowed
        }).toPromise();
    }

    public async changePassword(formValue: any): Promise<any> {
        return this.httpService.post({
            url: '/api/auth/changePassword',
            body: btoa(JSON.stringify(formValue))
            // body: JSON.stringify(btoa(JSON.stringify(formValue))),
            /* headers: {
                contentType: 'text/plain'
            } */
        }).toPromise();
    }
}
