import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonSlides, LoadingController, NavController, PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { BasePage } from 'src/app/_shared/components/base.page';
import { expandInOut } from 'src/app/_shared/animations';
import { UserModel } from 'src/app/users/models/user.model';
import { UserService } from '../user.service';
import { BattleModel } from 'src/app/battles/models/battle.model';
import { BattleCriteriaModel } from 'src/app/battles/models/battle-criteria.model';
import { AuthService } from 'src/app/auth/auth.service';
import { BattleService } from 'src/app/battles/battle.service';
import { UserDetailMenuComponent } from './_partials/menu/user-detail.menu';
import { BaseWalkThruPage } from 'src/app/_shared/components/base-walkthru.page';
import { BattleFilterModel } from 'src/app/battles/models/battle-filter.model';
import { BattleActivityModel } from 'src/app/battles/models/battle-activity.model';
import { BattleActivityCriteriaModel } from 'src/app/battles/models/battle-activity-criteria.model';
import { BattleActivityFilterModel } from 'src/app/battles/models/battle-activity-filter.model';
import { UserBattlesFilterComponent } from './_partials/filter/user-battles-filter.component';


@Component({
    selector: 'user-detail',
    templateUrl: 'user-detail.page.html',
    styleUrls: ['user-detail.page.scss'],
    animations: [expandInOut()],
    encapsulation: ViewEncapsulation.None
})
export class UserDetailPage extends BaseWalkThruPage {
    constructor(
        public route: ActivatedRoute,
        public loadingController: LoadingController,
        public navController: NavController,
        public popoverController: PopoverController,
        public authService: AuthService,
        public userService: UserService,
        public battleService: BattleService
    ) { super(loadingController); }

    @ViewChild('battlesFilter', { static: true }) public battlesFilter: UserBattlesFilterComponent;

    public user: UserModel;

    public stats: any;

    /* public solo: {
        filters: BattleFilterModel[],
        criteria?: BattleCriteriaModel,
        battles: {
            [filter: string]: BattleModel[]
        }
    };

    public both: {
        filters: BattleFilterModel[],
        criteria?: BehaviorSubject<BattleCriteriaModel>,
        battles: {
            [filter: string]: BattleModel[]
        }
    }; */

    async ionViewWillEnter() {
        super.ngOnInit();
        this.route.params
        .pipe(finalize(() => {
            console.log(this.constructor.name, 'routeParams.finalize');
        }))
        .pipe(takeUntil(this._onDestroy))
        .subscribe(async (params) => { // console.log(params);
            // if (this.user) this.walkThru.slideTo(0);
            await this.getUser(+params.userId);
        });
    //    this.getStats();
        this.relationshipTabs.updateAutoHeight();

        /* this.criteria.pipe(takeUntil(this._onDestroy))
        .subscribe((criteria) => {
            this.getBattles(criteria);
        }); */
    }

    public async refresh($event?, force?: boolean) {
        delete this.user;
        await this.getUser(+this.route.snapshot.params.userId, force);
        this.battlesFilter.refresh(force);
        if ($event) setTimeout(() => $event.target.complete(), 100);
    }

    private async getUser(userId, force?: boolean) { console.log('getUser:', userId, this.route.snapshot.params.userId);
        await this.startLoading('full');
        await this.userService.getUser(userId, force) // this.route.snapshot.params.userId)
        .then(response => this.user = response)
        .catch(error => this.alerts.set('error', error));
        // setTimeout(() => { // Allow infinite-scrolls to render/reset
            // this.activity.init();
            // this.page2.init();
        // });
        await this.stopLoading('full');
    }

    // private getStats() {
    //     this.userService.getStats(this.route.snapshot.params.userId)
    //     .then(response => this.stats = response)
    //     .catch(error => this.alerts.set('error', error));
    // }

    /* public setCriteria(area, filter: BattleFilterModel) {
        area.criteria = new BattleCriteriaModel({
            filter: filter,
            take: 5
        }); // console.log(filter);
        this.getBattles(area);
    } */

    @ViewChild('relationshipTabs', { static: true }) public relationshipTabs: IonSlides;
    public relationships = new class Relationships {
        constructor(private parent: UserDetailPage) { }

        public filters = [
            { label: 'Interests' },
            { label: 'Friends' },
            { label: 'Followers' },
            { label: 'Following' }
        ];

        public filter = { label: 'Interests' };

        public tabIndex: number = 0;

        public showMenu: boolean;

        public toggleMenu() {
            this.showMenu = !this.showMenu;
        }

        public select(tabIndex: number) {
            this.filter = this.filters[tabIndex];
            this.tabIndex = tabIndex;
            this.parent.walkThru.slideTo(1);
            this.parent.relationshipTabs.slideTo(tabIndex);
            this.showMenu = false;
        }
    }(this);

    public async presentPopover(ev: any) {
        const popover = await this.popoverController.create({
            component: UserDetailMenuComponent,
            componentProps: {
                user: this.user
            },
            event: ev,
            translucent: true
        });
        return await popover.present();
    }
}
