import { BaseModel } from 'src/app/_shared/models/_base.model';


export class BattleLikeFilterModel extends BaseModel {
    constructor(data?: BattleLikeFilterModel) {
        super(data);
    }

    public battleId?: number;
}
