import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { BaseWalkThruPage } from 'src/app/_shared/components/base-walkthru.page';
import { expandInOut } from 'src/app/_shared/animations';
import { UserService } from '../user.service';
import { UserModel } from '../models/user.model';
import { UserCriteriaModel } from '../models/user-criteria.model';
import { AuthService } from 'src/app/auth/auth.service';


interface AddFriendModel extends UserModel {
    isSelected: boolean;
}

@Component({
    selector: 'add-friends',
    templateUrl: 'add-friends.page.html',
    styleUrls: ['add-friends.page.scss'],
    animations: [expandInOut()]
})
export class AddFriendsPage extends BaseWalkThruPage { // implements OnInit, OnDestroy {
    constructor(
        @Inject(DOCUMENT) public document: Document,
        public loadingController: LoadingController,
        public authService: AuthService,
        public userService: UserService
    ) { super(loadingController); }

    public platforms: {
        label: string,
        friends: AddFriendModel[]
    }[];
    // public selectedFriends: { [key: number]: any } = {};

    async ionViewWillEnter() {
        super.ionViewWillEnter();
        await this.startLoading('full');
        await this.userService.import()
            .then(response => this.platforms = response)
            .catch(error => this.alerts.set('error', error));
        await this.stopLoading('full');

        // this.friends = [{ userId: 1, isSelected: true }];
        // this.walkThru.slideNext();
    }

    public async validate() {
        switch (this.currentSlide) {
            case 0:
                if (!this.platforms.some(platform => platform.friends.some(friend => friend.isSelected))) {
                    this.alerts.set('info', 'Select a Friend');
                    return false;
                } else
                    return await this.save();
        }
    }

    public search = new class Search {
        constructor(private parent: AddFriendsPage) {}

        results: Observable<UserModel[]>;

        getResults($event) { // console.log($event.target.value);
            if (!$event.target.value) {
                delete this.results;
                return;
            }

            this.parent.startLoading('partial');
            this.results = this.parent.userService.getUsers(new UserCriteriaModel({
                filter: {
                    query: $event.target.value
                }
            }))
            .pipe(map(response => {
                return response.filter(user => user.userId !== this.parent.authService.user.userId);
            }))
            .pipe(catchError(async error => {
                this.parent.alerts.set('error', error);
                return null;
            }));
            this.parent.stopLoading();
        }
    }(this);

    public async save() {
        await this.startLoading('partial');
        // const selectedFriends = this.friends.filter(friend => friend.isSelected);
        const selectedFriends = this.platforms.map(platform => platform.friends.map(friend => friend.isSelected));
        await this.userService.follow(selectedFriends)
        .then(() => { this.isCompleted = true; })
        .catch(error => this.alerts.set('error', error));
        await this.stopLoading();
        return !this.alerts.get('error');
    }
}
