import { BaseCriteriaModel } from 'src/app/_shared/models/base-criteria.model';
import { BattleLikeFilterModel } from './battle-like-filter.model';


export class BattleLikeCriteriaModel extends BaseCriteriaModel {
    constructor(data?: BattleLikeCriteriaModel) {
        super(data, {
            filter: BattleLikeFilterModel
        });
    }

    public filter?: BattleLikeFilterModel;
}
