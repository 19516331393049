import { Dictionary, DictionaryDefinition } from '../../_shared/models/_dictionary.model';


export class BattleRoundStatus extends DictionaryDefinition {
    constructor(data?: string) {
        super(data, BattleRoundStatuses);
    }
}

export const BattleRoundStatuses: Dictionary<BattleRoundStatus> = {
    inProgress: {
        key: 'inProgress',
        char: 'I',
        label: 'In Progress',
        index: 0
        // icon: 'fa-icon'
    },
    stopped: {
        key: 'stopped',
        char: 'S',
        label: 'Stopped',
        index: 1
    },
    finished: {
        key: 'finished',
        char: 'F',
        label: 'Finished',
        index: 3
    }
};
