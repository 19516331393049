import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { BlockUIModule } from 'ng-block-ui';
// import { ChartsModule } from 'ng2-charts';

// import { LoggedInComponent } from '../_shared/views/logged-in.component';
import { BattleHomePage } from './list/home/battle-home.page';
import { BattleHomeFilterComponent } from './list/home/_partials/filter/battle-home-filter.component';
import { BattleRequestPage } from './list/requests/battle-requests.page';
import { BattleRequestsFilterComponent } from './list/requests/_partials/filter/battle-requests-filter.component';

import { BattleDetailPage } from './detail/battle-detail.page';
import { AddBattlePage } from './add/add-battle.page';
import { BattleListItemComponent } from './_partials/list-item/battle-list-item.component';
import { BattleCardComponent } from './_partials/card/battle-card.component';

import { ProfilePicModule } from '../_shared/components/profile-pic/profile-pic.module';
import { AlertsModule } from '../_shared/components/alerts/alerts.module';
import { PipeModule } from '../_shared/pipes/pipe.module';
import { CountDownComponent } from '../_shared/components/count-down/count-down.component';
import { MatDatetimepickerModule } from '../_shared/components/datetimepicker/datetimepicker.module';
import { BattleActivityContentComponent } from './_partials/activity-content/battle-activity-content.component';
import { BattleActivityCardComponent } from './_partials/activity-card/battle-activity-card.component';
// import { MatNativeDatetimeModule } from '../_shared/components/datetimepicker/adapter/adapter.module';
// import { TimePickerModule } from '../_shared/components/time-picker/time-picker.module';

const BattleDeclarations = [
    BattleHomePage, BattleHomeFilterComponent,
    BattleRequestPage, BattleRequestsFilterComponent,
    BattleDetailPage, AddBattlePage,
    BattleListItemComponent, BattleCardComponent,
    BattleActivityCardComponent, BattleActivityContentComponent,
    CountDownComponent
];

@NgModule({
    imports: [
        CommonModule, FormsModule,
        IonicModule,
        // MatNativeDatetimeModule,
        AutoCompleteModule,
        MatDatetimepickerModule, // .forRoot(), // TimePickerModule,
        BlockUIModule,
        AlertsModule,
        ProfilePicModule,
        PipeModule,
        RouterModule.forChild([
            {
                path: '',
                // component: LoggedInComponent,
                children: [
                    /* {
                        path: 'battles',
                        component: BattleHomePage
                    }, {
                        path: 'battle/:battleId',
                        component: BattleDetailPage
                    } */
                ]
            }, /* {
                path: 'add',
                component: AddBattlePage
            } */
        ])
    ],
    declarations: [
        BattleDeclarations
    ],
    entryComponents: [
        // BattleRequestsFilterMenuComponent
    ],
    exports: [
        BattleDeclarations
    ]
})
export class BattleModule { }
