import { ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

import { BasePage } from 'src/app/_shared/components/base.page';


export class BaseWalkThruPage extends BasePage {

    @ViewChild('walkThru', { static: true }) public walkThru: IonSlides;

    public currentSlide: number = 0;

    public isCompleted: boolean;

    public ionViewWillEnter() {
        delete this.isCompleted;

        // Hijack Swipe & Validate
        this.walkThru.ionSlideNextStart.subscribe(async () => {
            this.alerts.reset();
            this.walkThru.slidePrev(null, false); // Prevent Slide
            if (await this.validate()) {
                this.walkThru.slideNext(null, false);
            }
        });
        this.walkThru.ionSlideDidChange.subscribe(async () => {
            this.currentSlide = await this.walkThru.getActiveIndex();
        });
    }

    public async back($event: MouseEvent) { // console.log('back', $event);
        if (this.isCompleted) return;
        if (await this.walkThru.getActiveIndex() > 0) {
            this.walkThru.slidePrev();
            $event.stopPropagation(); // Prevent 'defaultHref'
        } else {
            window.history.back();
            $event.stopPropagation(); // Prevent 'defaultHref'
        }
    }

    public async validate(): Promise<boolean> { return true; }

    public async save(): Promise<boolean> { return true; } // Return whether save was successful
}
