import { BaseModel } from 'src/app/_shared/models/_base.model';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleActivityType } from './battle-activity-types.dictionary';


export class BattleActivityFilterRequestModel extends BaseModel {
    constructor(data?: BattleActivityFilterRequestModel) {
        super(data, {
            users: (val: UserModel[]) => val.map(val => ({ userId: val.userId} as UserModel)),
            types: (val: BattleActivityType[]) => val.map(val => ({ key: val.key} as BattleActivityType))
        });
    }

    // public statuses?: BattleStatus[];
    public users?: UserModel[];
    public types?: BattleActivityType[];
    // public responses?: BattleEntrantResponse[];

    // public isFollowingOwner?: boolean;
    // public isListChallenge?: boolean;
}
