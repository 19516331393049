import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AutoCompleteModule } from 'ionic4-auto-complete';

import { AlertsModule } from '../_shared/components/alerts/alerts.module';
import { ProfilePicModule } from '../_shared/components/profile-pic/profile-pic.module';
import { BattleModule } from '../battles/battle.module';
import { PipeModule } from '../_shared/pipes/pipe.module';
import { AuthGuardService } from '../auth/auth-guard.service';

import { AddFriendsPage } from './add-friends/add-friends.page';
import { UserDetailPage } from './detail/user-detail.page';
import { UserDetailMenuComponent } from './detail/_partials/menu/user-detail.menu';
import { UserBattlesFilterComponent } from './detail/_partials/filter/user-battles-filter.component';
import { UserSettingsPage } from './settings/user-settings.page';
import { AddInterestsPage } from './add-interests/add-interests.page';


const FriendDeclarations = [
    AddFriendsPage, AddInterestsPage,
    UserDetailPage, UserDetailMenuComponent, UserBattlesFilterComponent,
    UserSettingsPage
];

@NgModule({
    imports: [
        CommonModule, FormsModule, // ReactiveFormsModule,
        IonicModule,
        AutoCompleteModule,
        AlertsModule, ProfilePicModule,
        PipeModule,
        BattleModule,
        RouterModule.forChild([
            {
                path: 'user/:userId',
                component: UserDetailPage,
                canActivate: [AuthGuardService]
            }, {
                path: 'friends',
                // component: LoggedInComponent,
                children: [
                    {
                        path: 'add',
                        component: AddFriendsPage
                    }
                ],
                canActivate: [AuthGuardService]
            }, {
                path: 'interests/add',
                component: AddInterestsPage,
                canActivate: [AuthGuardService]
            }, {
                path: 'settings',
                component: UserSettingsPage,
                canActivate: [AuthGuardService]
            }
        ])
    ],
    entryComponents: [
        UserDetailMenuComponent
    ],
    declarations: [
        FriendDeclarations
    ],
    exports: [
        FriendDeclarations
    ]
})
export class UserModule { }
