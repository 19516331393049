import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleSuggestionModel } from './battle-suggestion.model';


export class BattleCategoryModel extends BaseModel {
    constructor(data?: BattleCategoryModel) {
        super(data, {
            childCategories: [BattleCategoryModel],
            suggestions: [BattleSuggestionModel]
        }); // console.log('BattleCategoryModel', this);
    }

    public battleCategoryId?: number;
    public icon?: string;
    public label?: string;
    public description?: string;
    public childCategories?: BattleCategoryModel[];
    public suggestions?: BattleSuggestionModel[];
    public isFollowing?: boolean;
}
