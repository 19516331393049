import { Component, OnInit, OnDestroy, Inject, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ToastController, NavController, IonSelect } from '@ionic/angular';
import { AutoCompleteComponent, AutoCompleteService } from 'ionic4-auto-complete';
import { get } from 'lodash-es';

import { BaseWalkThruPage } from 'src/app/_shared/components/base-walkthru.page';
import { BattleModel } from '../models/battle.model';
import { BattleService } from '../battle.service';
import { expandInOut } from 'src/app/_shared/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleScoreTypes, BattleScoreType } from '../models/battle-score-type.dictionary';
import { BattleScoreOrders, BattleScoreOrder } from '../models/battle-score-order.dictionary';
import { BattleSuggestionModel } from '../models/battle-suggestion.model';
import { BattleCategoryModel } from '../models/battle-category.model';
import { UserService } from 'src/app/users/user.service';
import { BattlePrivacies, BattlePrivacy } from '../models/battle-privacy.dictionary';
import { takeUntil } from 'rxjs/operators';
import { DictionaryDefinition } from 'src/app/_shared/models/_dictionary.model';
import { BattleSuggestionFilterModel } from '../models/battle-suggestion-filter.model';

@Component({
    selector: 'add-battle',
    templateUrl: 'add-battle.page.html',
    styleUrls: ['add-battle.page.scss'],
    animations: [expandInOut()]
})
export class AddBattlePage extends BaseWalkThruPage {
    constructor(
        @Inject(DOCUMENT) public document: Document,
        private router: Router,
        private route: ActivatedRoute,
        public loadingController: LoadingController,
        private navController: NavController,
        private toastController: ToastController,
        private authService: AuthService,
        private userService: UserService,
        private battleService: BattleService
    ) { super(loadingController); }

    public battle: BattleModel = new BattleModel({
        privacy: BattlePrivacies.public,
        entrants: [],
        totalRounds: 1,
        // roundMins: 0,
        scoreType: BattleScoreTypes.points,
        scoreOrder: BattleScoreOrders.descending
    });

    public options: any = {
        privacies: BattlePrivacies,
        scoreTypes: BattleScoreTypes,
        scoreOrders: BattleScoreOrders
    };

    @ViewChild('autoComplete', { static: true }) autoComplete: AutoCompleteComponent;
    @ViewChildren(IonSelect) selects: QueryList<IonSelect>;

    async ionViewWillEnter() {
        super.ionViewWillEnter();
        this.startLoading('full');

        const queryParams: BattleModel = this.route.snapshot.queryParams || {};
        this.battle.isOpenInvite = !!queryParams.isOpenInvite;
        this.battle.hasCrowdVoting = !!queryParams.hasCrowdVoting;
        if (queryParams.privacy) this.battle.privacy = BattlePrivacies[queryParams.privacy as any];
        if (queryParams.scoreType) {
            this.battle.scoreType = BattleScoreTypes[queryParams.scoreType as any];
            if (this.battle.scoreType.key === BattleScoreTypes.points.key)
                this.battle.scoreOrder = BattleScoreOrders.descending;
            else if (this.battle.scoreType.key === BattleScoreTypes.timed.key)
                this.battle.scoreOrder = BattleScoreOrders.ascending;
        }

        /* const type = get(this.route.snapshot.queryParams, 'type');
        switch (type) {
            case 'crowd-favourite':
                this.battle.what = 'Crowd Favourite...';
                this.autoComplete.setValue(this.battle.what);
                this.battle.hasCrowdVoting = true;
                this.battle.privacy = BattlePrivacies.anyone;
                this.battle.scoreType = BattleScoreTypes.none;
                break;
            case 'open-invite':
                this.battle.isOpenInvite = true;
                this.battle.privacy = BattlePrivacies.anyone;
                break;
        } */

        await Promise.all([
            this.what.init(),
            this.who.init()
        ]);
        this.stopLoading('full');
    }

    /* ngOnDestroy() {
        super.ngOnDestroy();
    } */

    compareDefinitions(a: DictionaryDefinition, b: DictionaryDefinition) { // console.log('compareWith', a, b);
        return get(a, 'key') === get(b, 'key');
    }

    /* public async next() {
        this.alerts.reset();
        switch (await this.steps.getActiveIndex()) {
            case 0:
                if (!this.battle.what) {
                    this.alerts.info = 'Enter a Challenge';
                    return;
                }
                break;
            case 1:
                if (!this.battle.entrants.length) {
                    this.alerts.info = 'Select Entrants';
                    return;
                }
                break;
        }
        this.steps.slideNext();
    } */

    // What
    public what = new class What {
        constructor(private parent: AddBattlePage) {}

        suggestions: BattleSuggestionModel[];

        public async init() {
            const queryParams: BattleSuggestionFilterModel = this.parent.route.snapshot.queryParams || {};
            await this.parent.battleService.getBattleSuggestions({
                filter: {
                    categoryIds: (queryParams.categoryIds as any || '').split(','),
                    isOpenInvite: queryParams.isOpenInvite,
                    hasCrowdVoting: queryParams.hasCrowdVoting
                }
            }).toPromise()
            .then(response => { // console.log(response);
                this.suggestions = response;
            });
        }

        public validate(): boolean {
            if (this.parent.battle.what) return true;
            this.parent.alerts.set('info', 'Enter a Challenge');
            return false;
        }

        public autoComplete = new class AutoComplete implements AutoCompleteService {
            constructor(private parent: What) {}

            public labelAttribute: string = 'what';

            public getResults(keyword: string) {
                const results = keyword ? [new BattleSuggestionModel({
                    what: keyword,
                    icon: 'flaticon-battles'
                })] : [];
                if (keyword) Object.assign(this.parent.parent.battle, results[0]);

                keyword = keyword.toLowerCase();
                return results.concat(this.parent.suggestions.filter(suggestion => suggestion.what.toLowerCase().indexOf(keyword) >= 0));
                /* return results.concat(this.parent.suggestionCategories.reduce((mem: BattleCategoryModel[], genre: BattleCategoryModel) => {
                    const matchingSuggestions = genre.childCategories.filter(suggestion => suggestion.what.toLowerCase().indexOf(keyword) >= 0);
                    if (matchingSuggestions.length)
                        return mem.concat(matchingSuggestions);
                    else if (genre.label.toLowerCase().indexOf(keyword) >= 0)
                        return mem.concat(genre.childCategories);
                    else
                        return mem;
                }, [])); */
            }
        }(this);

        public setSuggestion(suggestion: BattleModel) { // console.log('setSuggestion', suggestion);
            if (suggestion instanceof BattleSuggestionModel)
                Object.assign(this.parent.battle, suggestion);
            console.log('setSuggestion', suggestion, this.parent.battle);
        }

        public autoBlur($event) {
            if (!$event.target.value)
                Object.assign(this.parent.battle, new BattleModel({
                    what: '',
                    icon: ''
                }));
            this.parent.alerts.reset();
            /* if ($event.target.value !== this.parent.battle.what)
            //     return;
            // else
                Object.assign(this.parent.battle, this.childCategories.find(suggestion => {
                    return $event.target.value === this.parent.battle.what;
                })); */
            console.log('autoBlur', this.parent.battle);
        }
    }(this);

    // Who
    public who = new class Who {
        constructor(private parent: AddBattlePage) {}

        public users: AddBattleUserModel[] = [];

        public async init() {
            // Add Current User
            this.addUser(this.parent.authService.user, true);

            await Promise.all([
                // Add QueryString Users
                new Promise(async (resolve) => {
                    if (this.parent.route.snapshot.queryParams.userIds) {
                        await Promise.all(
                            this.parent.route.snapshot.queryParams.userIds.split(',').map(userId => {
                                return this.parent.userService.getUser(userId).then((user: AddBattleUserModel) => {
                                    this.addUser(user, true);
                                });
                            })
                        );
                    }
                    resolve();
                }),

                // Add Friends
                this.parent.authService.getFriends()
                .then((response) => { // console.log(response);
                    response.forEach(user => this.addUser(user));
                    // this.users = [this.parent.authService.user].concat(response);
                    // this.toggle(this.users[0]);
                })
            ]);

            console.log(this.parent.battle);
        }

        public validate(): boolean {
            if (this.parent.battle.isOpenInvite) return true;
            if (this.parent.battle.entrants.length) return true;
            this.parent.alerts.set('info', 'Select Entrants');
            return false;
        }

        public addUser(user: AddBattleUserModel, isSelected?: boolean) {
            if (!this.users.find(existingUser => existingUser.userId === user.userId))
                this.users.push(user);
            if (isSelected)
                this.toggle(user);
        }

        public toggle(user: AddBattleUserModel) {
            this.parent.alerts.reset();
            user.isSelected = !user.isSelected;
            if (user.isSelected)
                this.parent.battle.entrants.push({ user: user });
            else
                this.parent.battle.entrants = this.parent.battle.entrants.filter(entrant => entrant.user.name !== user.name);
            // console.log('entrants', this.parent.battle.entrants);
        }
    }(this);

    // When
    public rules = new class Rules {
        constructor(private parent: AddBattlePage) {}

        public onChangeType() {
            switch (this.parent.battle.scoreType) {
                case BattleScoreTypes.timed:
                    this.parent.battle.scoreOrder = BattleScoreOrders.ascending;
                    break;
                default:
                    this.parent.battle.scoreOrder = BattleScoreOrders.descending;
            }
        }

        /* public setScoring($event) {
            const value = $event.detail.value.split(',');
            this.parent.battle.scoreType = new BattleScoreType(value[0]);
            this.parent.battle.scoreOrder = new BattleScoreOrder(value[1]);
        } */
    }(this);

    public async validate() {
        switch (this.currentSlide) {
            case 0:
                return this.what.validate();
            case 1:
                return this.who.validate();
        }
    }

    public async save() { console.log('save', this.battle);
        this.startLoading();
        await this.battleService.create(this.battle)
        .then(async (battle: BattleModel) => { console.log(battle);
            await this.navController.pop();
            this.router.navigate(['/battle', battle.battleId]);
        })
        .catch((error) => {
            this.alerts.set('error', error);
            this.toastController.create({
                message: 'There was an problem creating the battle',
                duration: 5000
            }).then(toast => toast.present());
        });
        this.stopLoading();
        return !this.alerts.get('error');
    }
}


interface AddBattleUserModel extends UserModel {
    isSelected?: boolean;
}
