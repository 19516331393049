import { Directive, Host, Output, EventEmitter, OnInit, HostListener, ContentChildren, AfterContentInit, QueryList, OnDestroy, SkipSelf, ElementRef } from '@angular/core';
import { IonSlides, IonItem, IonApp } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AlertsComponent } from '../components/alerts/alerts.component';
import { Subject } from 'rxjs';

@Directive({
    selector: '[ionSlideExtras]'
})
export class IonSlideExtrasDirective implements AfterContentInit, OnDestroy {
    constructor(
        @Host() private elementRef: ElementRef,
        @Host() private ionSlides: IonSlides
    ) { }

    @Output() ionSlideWillChange: EventEmitter<any> = new EventEmitter();

    @ContentChildren(NgForm, {
        descendants: true
    }) ngForms: QueryList<NgForm>;

    @ContentChildren(AlertsComponent, {
        descendants: true
    }) alerts: QueryList<AlertsComponent>;

    ngAfterContentInit() { // console.log(this);
        this.ngForms.forEach(ngForm => { // console.log(ngForm);
            ngForm.statusChanges.pipe(debounceTime(10)).pipe(takeUntil(this._onDestroy))
            .subscribe(status => { // console.log(status);
                if (this.elementRef.nativeElement.isConnected)
                    this.ionSlides.updateAutoHeight();
            });
        });

        /* this.alerts.forEach(alert => { console.log(alert);

        });

        this.ionSlides.ionSlideWillChange.subscribe(async ($event) => { // console.log($event);
            this.ionSlideWillChange.emit(Object.assign({
                detail: {
                    value: await this.ionSlides.getActiveIndex()
                }
            }, $event));
        }); */
    }

    public _onDestroy: Subject<void> = new Subject();

    ngOnDestroy() {
        this._onDestroy.complete();
    }
}
