import { Dictionary, DictionaryDefinition } from '../../_shared/models/_dictionary.model';


export class BattleActivityType extends DictionaryDefinition {
    constructor(data?: string) {
        super(data, BattleActivityTypes);
    }
}

export const BattleActivityTypes: Dictionary<BattleActivityType> = {
    created: {
        key: 'created',
        char: 'C',
        label: 'Created the event',
        // index: 0
        // icon: 'fa-icon'
    },
    responded: {
        key: 'responded',
        char: 'R',
        label: 'Responded',
        // index: 1
    },
    commented: {
        key: 'commented',
        char: 'D',
        label: 'Commented',
        // index: 2
    },
    started: {
        key: 'started',
        char: 'S',
        label: 'Started the event',
        // index: 3
    },
    finished: {
        key: 'finished',
        char: 'F',
        label: 'Finished the event',
        // index: 4
    }
};
