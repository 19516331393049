import { APP_INITIALIZER, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import './_shared/helpers/dates';
import { AppComponent } from './app.component';
import { AppRoutingModule, CustomReuseStrategy } from './app-routing.module';
import { ProfilePicModule } from './_shared/components/profile-pic/profile-pic.module';

import { AuthModule } from './auth/auth.module';
import { HomePageModule } from './home/home.module';
import { BattleModule } from './battles/battle.module';

import { LoggedInComponent } from './_shared/views/logged-in.component';
import { UserModule } from './users/user.module';
import { environment } from '../environments/environment';
import { HttpService } from './_shared/services/http.service';
import { AppConfig } from './_shared/models/app-config.model';
import { DOCUMENT } from '@angular/common';
import { BlockUIModule } from 'ng-block-ui';


@NgModule({
    declarations: [
        AppComponent,
        LoggedInComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule, BrowserAnimationsModule,
        AppRoutingModule, HttpClientModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        BlockUIModule.forRoot(),
        ProfilePicModule,
        AuthModule,
        HomePageModule,
        BattleModule,
        UserModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy}, //  IonicRouteStrategy },
        { provide: APP_INITIALIZER, useFactory: (
            document: Document,
            appConfig: AppConfig,
            httpService: HttpService
            ) => {
            // Fix FB Login query code being placed before #
            if (document.location.search) {
                document.defaultView.history.replaceState({}, '', document.location.origin + document.location.pathname + document.location.hash + '&' + document.location.search.substr(1));
                // document.location.href = document.location.origin + document.location.pathname + document.location.hash + '&' + document.location.search.substr(1);
            }

            return async () => {
                await httpService.request({
                    url: 'app-config.json',
                    isExternal: true
                }).toPromise()
                .then((response: AppConfig) => {
                    Object.assign(appConfig, response);
                    // httpService.api = response.api;
                })
                .catch((error) => { // console.error('AppConfig Error', error);
                    // debugger;
                });
            };
        }, deps: [ DOCUMENT, AppConfig, HttpService ], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
