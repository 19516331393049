import { OnInit, Input, Component, SimpleChanges, OnChanges, Output, EventEmitter, HostBinding } from '@angular/core';
import {
    differenceInYears, differenceInMonths, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds,
    subYears, subMonths, subDays, subHours, subMinutes, subSeconds
} from 'date-fns/esm';

@Component({
    selector: 'count-down',
    templateUrl: 'count-down.component.html',
    styleUrls: [ './count-down.component.scss' ]
})
export class CountDownComponent implements OnInit, OnChanges {

    @Input() private date: Date;

    @Input() @HostBinding('class') private type: 'simple' | 'full' = 'full';

    @Input() public isRunning: boolean = true;

    @Output() public finish: EventEmitter<void> = new EventEmitter();

    private units: string;

    private runningTimer: NodeJS.Timer;

    private endTimer: NodeJS.Timer;

    public parts: {
        label: string,
        functions: {
            difference: (from: Date, to: Date) => number,
            subtract?: (date: Date, amount: number) => Date
        },
        value?: number | string,
        isVisible?: boolean
    }[];

    // LifeCycle
    ngOnChanges(changes: SimpleChanges) { // console.log('count-down changes', changes);
        // if (changes.units) this.buildParts();
        if (changes.type || !this.parts) {
            switch (this.type) {
                case 'simple':
                    this.units = 'year,month,day,hour,minute,second,tenths';
                    break;
                default:
                    this.units = 'year,month,day,hour,minute,second';
            }
            this.buildParts();
        }

        if (changes.date) this.calculate();

        // if (changes.isRunning) { console.log('change isRunning', this.isRunning);
            if (this.isRunning)
                this.startTimer();
            else
                clearInterval(this.runningTimer);
        // }
    }

    ngOnInit() { // console.log('init');
       // if (!this.parts) this.buildParts();

       //  this.calculate();
       if (this.isRunning) this.startTimer();
    }

    // Private Functions
    private buildParts(): void {
        this.parts = this.units.split(',').map((unit: string) => {
            return {
                label: unit,
                functions: (() => {
                    switch (unit) {
                        case 'year':
                            return {
                                // label: 'Year',
                                // functions: {
                                    difference: differenceInYears,
                                    subtract: subYears
                                // }
                            };
                        case 'month':
                            return {
                                // label: 'Month',
                                // functions: {
                                    difference: differenceInMonths,
                                    subtract: subMonths
                                // }
                            };
                        case 'day':
                            return {
                                // label: 'Day',
                                // functions: {
                                    difference: differenceInDays,
                                    subtract: subDays
                                // }
                            };
                        case 'hour':
                            return {
                                // label: 'Hour',
                                // functions: {
                                    difference: differenceInHours,
                                    subtract: subHours
                                // }
                            };
                        case 'minute':
                            return {
                                // label: 'Minute',
                                // functions: {
                                    difference: differenceInMinutes,
                                    subtract: subMinutes
                                // }
                            };
                        case 'second':
                            return {
                                // label: 'Second',
                                // functions: {
                                    difference: differenceInSeconds,
                                    subtract: subSeconds
                                // }
                            };
                        case 'tenths':
                            return {
                                difference: (from: Date, to: Date) => {
                                    const out = (to.getTime() - from.getTime()) / 100;
                                    return (out < 0) ? Math.ceil(out) : Math.floor(out);
                                }
                            };
                    }
                })()
            };
        });
    }

    private startTimer(): void {
        if (!this.date) return;
        if (this.runningTimer) clearTimeout(this.runningTimer);
        this.runningTimer = setInterval(() => this.calculate(), (() => {
            switch (this.parts[this.parts.length - 1].label) {
                case 'tenths':
                    return 100;
                default:
                    return 1000;
            }
        })());

        // Schedule finish
        const endMs = this.date.getTime() - new Date().getTime(); console.log('endMins', endMs / 60 / 1000);
        if (endMs > 0) this.endTimer = setTimeout(() => {
            clearInterval(this.runningTimer);
            this.finish.emit();
        }, endMs);
    }

    private calculate(): void {
        if (!this.date) return;

        const now = new Date();
        let until = this.date; //  new Date(this.date);

        const diff = now.getTime() - until.getTime();
        if (diff === 0) {
            clearInterval(this.runningTimer);
        }

        this.parts.forEach((part, i) => {
            part.value = part.functions.difference(until, now); // Calc part difference
            part.isVisible = !!part.value || part.label === 'second' || (this.parts[i - 1] || {} as any).isVisible; // Show from first part with value
            if (part.value && i < this.parts.length && part.functions.subtract)
                until = part.functions.subtract(until, part.value); // Subtract difference

            if (part.value < 0) part.value -= part.value * 2; // Ensure + number

            if (this.type === 'simple') {
                if (part.value <= 9) switch (part.label) {
                    case 'minute':
                    case 'second':
                        part.value = '0' + part.value;
                        break;
                }
            }
        });
    }

    // Public Functions
    public trackByFn(i, value): string { // console.log(value.label);
        return value.label;
    }
}
