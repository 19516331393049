import { BaseCriteriaRequestModel } from 'src/app/_shared/models/base-criteria-request.model';
import { BattleCriteriaModel } from './battle-criteria.model';
import { BattleFilterRequestModel } from './battle-filter-request.model';


export class BattleCriteriaRequestModel extends BaseCriteriaRequestModel {
    constructor(data?: BattleCriteriaModel) {
        super(data, {
            filter: BattleFilterRequestModel
        });

        // delete this.filter;
        // this['filter.key'] = 'test';
    }
}
