import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleRoundStatus } from './battle-round-statuses.dictionary';


export class BattleRoundModel extends BaseModel {
    constructor(data?: BattleRoundModel) {
        super(data, {
            startedAt: Date,
            pausedAt: Date,
            status: BattleRoundStatus,
        }); // console.log('BattleRoundModel', this);
    }

    public roundId?: number;
    public battleId?: number;
    public status?: BattleRoundStatus;
    public startedAt?: Date;
    public pausedAt?: Date;
    public stoppageTime?: number;
}
