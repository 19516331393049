import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { LoginPage } from './login.page';
import { DirectivesModule } from '../_shared/directives/directives.module';
import { AlertsModule } from '../_shared/components/alerts/alerts.module';

@NgModule({
    imports: [
        CommonModule, FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: '',
                component: LoginPage
            }
        ]),
        DirectivesModule,
        AlertsModule
    ],
    declarations: [LoginPage]
})
export class AuthModule { }
