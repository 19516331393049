import { Dictionary, DictionaryDefinition } from '../../_shared/models/_dictionary.model';


export class BattleEntrantResponse extends DictionaryDefinition {
    constructor(data?: string | BattleEntrantResponse) {
        super(data, BattleEntrantResponses);
        
        if (!this.char)
            Object.assign(this, BattleEntrantResponses.none, {
                // label: 'Bailed'
            });
    }

    public actionLabel?: string;
}

export const BattleEntrantResponses: Dictionary<BattleEntrantResponse> = {
    none: {
        key: 'none',
        char: null,
        label: 'No Response',
        icon: 'help',
        color: 'tertiary'
    },
    accept: {
        key: 'accept',
        char: 'A',
        label: 'Accepted',
        actionlabel: 'Accept',
        icon: 'checkmark',
        color: 'success'
    },
    decline: {
        key: 'decline',
        char: 'D',
        label: 'Declined',
        actionlabel: 'Decline',
        icon: 'close',
        color: 'danger'
    }
};
