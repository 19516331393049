import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleStatus } from './battle-statuses.dictionary';
import { BattleModel } from './battle.model';
import { BattleScoreOrder } from './battle-score-order.dictionary';
import { BattleScoreType } from './battle-score-type.dictionary';
import { BattleEntrantRequestModel } from './battle-entrant-request.model';
import { BattlePrivacy } from './battle-privacy.dictionary';


export class BattleRequestModel extends BattleModel {
    constructor(data: BattleModel) {
        super(data, {
            status: (val: BattleStatus) => val.char,
            entrants: [BattleEntrantRequestModel],
            // rounds: [BattleRoundModel],
            // test: Date,
            privacy: (val: BattlePrivacy) => val.char,
            scoreType: (val: BattleScoreType) => val.char,
            scoreOrder: (val: BattleScoreOrder) => val.char,
            security: null
            // umpires: [UserModel]
        }); console.log('BattleRequestModel', this);
    }
}
