import { BaseCriteriaModel } from 'src/app/_shared/models/base-criteria.model';
import { BattleActivityFilterRequestModel } from './battle-activity-filter-request.model';


export class BattleActivityCriteriaRequestModel extends BaseCriteriaModel {
    constructor(data?: BattleActivityCriteriaRequestModel) {
        super(data, {
            filter: BattleActivityFilterRequestModel
        });
    }

    public filter?: BattleActivityFilterRequestModel;
}
