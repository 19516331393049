import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { map, mapTo, toArray, mergeMap } from 'rxjs/operators';

import { SocketService } from '../_shared/services/socket.service';
import { HttpService } from '../_shared/services/http.service';
import { BattleModel } from './models/battle.model';
import { BattleRequestModel } from './models/battle-request.model';
import { BattleEntrantResponse } from './models/battle-entrant-responses.dictionary';
import { BattleCriteriaModel } from './models/battle-criteria.model';
import { BattleCriteriaRequestModel } from './models/battle-criteria-request.model';
import { BattleSuggestionModel } from './models/battle-suggestion.model';
import { BattleCategoryModel } from './models/battle-category.model';
import { BattleSuggestionFilterModel } from './models/battle-suggestion-filter.model';
import { BattleSuggestionCriteriaModel } from './models/battle-suggestion-criteria.model';
import { BattleCategoryFilterModel } from './models/battle-category-filter.model';
import { BattleCategoryCriteriaModel } from './models/battle-category-criteria.model';
import { BattleActivityModel } from './models/battle-activity.model';
import { BattleActivityCriteriaModel } from './models/battle-activity-criteria.model';
import { BattleActivityCriteriaRequestModel } from './models/battle-activity-criteria-request.model';
import { BattleCommentModel } from './models/battle-comment.model';
import { BattleCommentCriteriaModel } from './models/battle-comment-criteria.model';
import { BattleLikeCriteriaModel } from './models/battle-like-criteria.model';
import { BattleLikeModel } from './models/battle-like.model';


@Injectable({
    providedIn: 'root'
})
export class BattleService { // xtends BaseHubService {
    constructor(
        @Inject(DOCUMENT) public document: Document,
        private httpService: HttpService,
        private socketService: SocketService,
        // private toastController: ToastController
    ) { // console.log('BattleService');
        // this.connect();
        /* this.socketService.connect().then((hub: HubConnection) => {
        }); */
    }

    public getBattles(criteria: BattleCriteriaModel, force?: boolean): Promise<BattleModel[]> {
        return this.httpService.get({
            url: '/api/battle/list',
            query: new BattleCriteriaRequestModel(criteria)
        }, force).toPromise()
        .then((response: BattleModel[]) => { // console.log('getBattles', response);
            return response.map(battle => new BattleModel(battle));
        });
    }

    public getCategories(criteria?: BattleCategoryCriteriaModel) { console.log('getCategories', criteria);
        return this.httpService.get({
            url: '/api/battle/categories',
            query: criteria /* parentCategoryId ? {
                parentCategoryId: parentCategoryId
            } : null */
        }, true)
        .pipe(map((response: BattleCategoryModel[]) => { // console.log('battleCategories', response);
            return response.map(val => new BattleCategoryModel(val));
        }));
    }

    /* public getCategory(id: number) {
        return this.httpService.get('/api/battle/category/' + id)
        .pipe(map((response: BattleCategoryModel[]) => { // console.log('battleCategories', response);
            return response.map(val => new BattleCategoryModel(val));
        }));
    } */

    public getBattleSuggestions(criteria?: BattleSuggestionCriteriaModel) {
        return this.httpService.get<BattleSuggestionModel[]>({
            url: '/api/battle/suggestions',
            query: criteria
        })
        .pipe(map(response => { // console.log('gotBattleSuggestions', response);
            return response.map(val => new BattleSuggestionModel(val));
        }));
    }

    public create(battle: BattleModel): Promise<BattleModel> {
        return this.httpService.post({
            url: '/api/battle/create',
            body: new BattleRequestModel(battle)
        }).toPromise()
        .then((response: BattleModel) => { // console.log(response);
            return new BattleModel(response);
        });
    }

    public getBattle(battleId: number, force: boolean): Promise<BattleModel> {
        return this.httpService.get({
            url: '/api/battle/' + battleId
        }, force).toPromise()
        .then((response: BattleModel) => { // console.log(response);
            return new BattleModel(response);
        });
    }

    public join(battleId: number): Promise<BattleModel> {
        return this.httpService.post({
            url: '/api/battle/join',
            body: {
                battleId: battleId,
                connectionId: this.socketService.connectionId // this.authService.me
            }
        }).toPromise()
        .then((response: BattleModel) => { // console.log(response);
            return new BattleModel(response);
        });
    }

    public leave(battleId: number): Promise<any> {
        return this.httpService.post({
            url: '/api/battle/leave',
            body: {
                battleId: battleId,
                connectionId: this.socketService.connectionId // this.authService.me
            }
        }).toPromise();
    }

    public getActivity(criteria: BattleActivityCriteriaModel, force?: boolean): Promise<BattleActivityModel[]> {
        return this.httpService.get({
            url: '/api/battle/activity',
            query: new BattleActivityCriteriaRequestModel(criteria)
        }, force).toPromise()
        .then((response: BattleActivityModel[]) => { // console.log('getBattles', response);
            return response.map(activity => new BattleActivityModel(activity, {
                battle: BattleModel
            }));
        });
    }

    // Comments
    public getComments(criteria: BattleCommentCriteriaModel, force?: boolean): Promise<BattleCommentModel[]> {
        return this.httpService.get({
            url: '/api/battle/comments',
            query: criteria // new BattleCommentCriteriaRequestModel(criteria)
        }, force).toPromise()
        .then((response: BattleCommentModel[]) => { // console.log('getComments', response);
            return response.map(val => new BattleCommentModel(val));
        });
    }
    public comment(battleId: number, comment: string): Promise<any> {
        return this.httpService.post({
            url: '/api/battle/' + battleId + '/comment',
            body: JSON.stringify(comment)
        }).toPromise();
    }

    // Likes
    public getLikes(criteria: BattleLikeCriteriaModel, force?: boolean): Promise<BattleLikeModel[]> {
        return this.httpService.get({
            url: '/api/battle/likes',
            query: criteria // new BattleLikeCriteriaRequestModel(criteria)
        }, force).toPromise()
        .then((response: BattleLikeModel[]) => { // console.log('getLikesv', response);
            return response.map(val => new BattleLikeModel(val));
        });
    }
    public like(battleId: number, unlike?: boolean): Promise<any> {
        return this.httpService.post({
            url: '/api/battle/' + battleId + '/like',
            query: unlike ? { unlike: true } : null
        }).toPromise();
    }

    public respond(battleId: number, response: BattleEntrantResponse): Promise<any> {
        return this.httpService.post('/api/battle/' + battleId + '/respond/' + response.key).toPromise();
    }

    public begin(battleId: number): Promise<any> {
        return this.httpService.post('/api/battle/' + battleId + '/begin').toPromise();
    }

    // Rounds
    public createRound(battleId: number): Promise<any> {
        return this.httpService.post('/api/battle/' + battleId + '/round/create').toPromise();
    }

    public startRound(battleId: number, roundId: number): Promise<any> {
        return this.httpService.post('/api/battle/' + battleId + '/round/' + roundId + '/start').toPromise();
    }

    public pauseRound(battleId: number, roundId: number): Promise<any> {
        return this.httpService.post('/api/battle/' + battleId + '/round/' + roundId + '/pause').toPromise();
    }

    public resumeRound(battleId: number, roundId: number): Promise<any> {
        return this.httpService.post('/api/battle/' + battleId + '/round/' + roundId + '/resume').toPromise();
    }

    public endRound(battleId: number, roundId: number): Promise<any> {
        return this.httpService.post('/api/battle/' + battleId + '/round/' + roundId + '/end').toPromise();
    }

    public score(battleId: number, roundId: number, entrantId: number, score: number): Promise<any> {
        return this.httpService.post({
            url: '/api/battle/' + battleId + '/score/' + roundId,
            query: {
                entrantId: entrantId,
                // roundId: roundId,
                score: score
            }
        }).toPromise();
    }

    public vote(battleId: number, entrantId: number): Promise<any> {
        return this.httpService.post({
            url: '/api/battle/' + battleId + '/vote/' + entrantId
        }).toPromise();
    }
}
