import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleStatus } from './battle-statuses.dictionary';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleEntrantResponse } from './battle-entrant-responses.dictionary';
import { BattleFilterModel } from './battle-filter.model';


export class BattleFilterRequestModel extends BaseModel {
    constructor(data?: BattleFilterModel) { // console.log(data);
        super(data, {
            statuses: (val: BattleStatus[]) => val.map(val => val.key),
            responses: (val: BattleStatus[]) => val.map(val => val.key),
            entrants: (val: UserModel[]) => val.map(val => ({ userId: val.userId} as UserModel))
        }); // console.log(this, data);

        // delete this.label;
    }

    // public label?: string;
    // public key?: string;
    // public statuses?: BattleStatus[];
    // public entrants?: UserModel[];
    // public responses?: BattleEntrantResponse[];
}
