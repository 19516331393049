import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './auth/auth-guard.service';
import { LoginPage } from './auth/login.page';
import { LoggedInComponent } from './_shared/views/logged-in.component';
import { HomePage } from './home/home.page';
import { BattleRequestPage } from './battles/list/requests/battle-requests.page';
import { BattleHomePage } from './battles/list/home/battle-home.page';
import { BattleDetailPage } from './battles/detail/battle-detail.page';
import { AddBattlePage } from './battles/add/add-battle.page';

const routes: Routes = [
    {
        // pathMatch: 'full',
        path: 'login',
        component: LoginPage,
    }, {
        path: '',
        component: LoggedInComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '', pathMatch: 'full', // redirectTo: 'home',
                component: HomePage
                // loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
            }, {
                path: 'invites',
                component: BattleRequestPage,
                // loadChildren: () => import('./battles/battle.module').then(m => m.BattleModule)
            }, {
                path: 'battles',
                component: BattleHomePage,
                // loadChildren: () => import('./battles/battle.module').then(m => m.BattleModule)
            }, {
                path: 'battles',
                component: BattleHomePage,
                // loadChildren: () => import('./battles/battle.module').then(m => m.BattleModule)
            } /*, {
                path: 'list',
                loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
            } */
        ]
    }, {
        path: 'battle/add',
        component: AddBattlePage,
        canActivate: [AuthGuardService]
    }, {
        path: 'battle/:battleId',
        component: BattleDetailPage,
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            preloadingStrategy: PreloadAllModules,
            // enableTracing: true
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }

export class CustomReuseStrategy implements RouteReuseStrategy {
    routesToCache: string[] = [
        // "dashboard"
    ];

    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    // Decides if the route should be stored
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
       return this.routesToCache.indexOf(route.routeConfig.path) > -1;
    }

    // Store the information for the route we're destructing
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
       this.storedRouteHandles.set(route.routeConfig.path, handle);
    }

    // Return true if we have a stored route object for the next route
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
       return this.storedRouteHandles.has(route.routeConfig.path);
    }

    // If we returned true in shouldAttach(), now return the actual route data for restoration
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
       return this.storedRouteHandles.get(route.routeConfig.path);
    }

    // Reuse the route if we're going to and from the same route
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
       return future.routeConfig === curr.routeConfig;
    }
}