import { Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavController, LoadingController, PopoverController, IonSlides } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';

import { animateList, expandInOut } from 'src/app/_shared/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { BattleListPage } from '../battle-list.page';
import { BattleService } from '../../battle.service';


@Component({
    selector: 'battle-requests',
    templateUrl: 'battle-requests.page.html',
    // styleUrls: ['battle-requests.page.scss'],
    animations: [animateList()]
})
export class BattleRequestPage extends BattleListPage {
    constructor(
        // public navController: NavController,
        public loadingController: LoadingController,
        // public popoverController: PopoverController,
        public authService: AuthService,
        public battleService: BattleService
    ) { super(loadingController, authService, battleService); }

    public groupProperty = 'created';

    /* public async refresh($event?, force?: boolean) {
        delete this.battles[this.criteria.value.filter.key];
        this.criteria.value.skip = 0;
        await this.getBattles(this.criteria.value, force);
        if ($event) setTimeout(() => $event.target.complete(), 100);
    }

    public async getBattles(criteria: BattleCriteriaModel, force?: boolean, $event?) {
        await this.startLoading('partial');
        this.alerts.reset();

        if (!this.battles[criteria.filter.key]) {
            this.battles[criteria.filter.key] = new GroupedArray({
                getKey: battle => +format((battle.created || new Date()), 'yyyyMMdd'),
                getLabel: battle => format((battle.created || new Date()), 'MMM do')
            });
        } else {
            criteria.skip += criteria.take;
        }

        if (!this.battles[criteria.filter.key].hasNoMore) await this.battleService.getBattles(criteria, force)
        .then(response => {
            this.battles[criteria.filter.key].merge(response);
        })
        .catch(error => this.alerts.set('error', error));
        this.stopLoading();
        if ($event) $event.target.complete();
    } */
}
