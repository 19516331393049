import { BaseModel } from 'src/app/_shared/models/_base.model';


export class BattleSecurityModel extends BaseModel {
    constructor(data?: BattleSecurityModel) {
        super(data); // console.log('BattleSecurityModel', this);
    }

    public hasTimeToRespond?: boolean;
    public isUmpire?: boolean;
    public isEntrant?: boolean;
    public isLiked?: boolean;
}
