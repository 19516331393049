import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleStatus } from './battle-statuses.dictionary';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleEntrantResponse } from './battle-entrant-responses.dictionary';


export class BattleFilterModel extends BaseModel {
    constructor(data?: BattleFilterModel) {
        super(data, {
            statuses: [BattleStatus],
            entrants: [UserModel],
            responses: [BattleEntrantResponse]
        });
    }

    public key?: string; // Sent / Received

    public statuses?: BattleStatus[];
    public entrants?: UserModel[];
    public responses?: BattleEntrantResponse[];

    public isFollowingOwner?: boolean;
    public isListChallenge?: boolean;
}
