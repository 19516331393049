import { UserModel } from 'src/app/users/models/user.model';
import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleModel } from './battle.model';


export class BattleCommentModel extends BaseModel {
    constructor(data?: BattleCommentModel, dictionary: any = {}) {
        super(data, Object.assign({
            user: UserModel,
            // battle: BattleModel
        }, dictionary)); // console.log('BattleCommentModel', this);
    }

    public battleId?: number;
    public battle?: BattleModel;
    public user?: UserModel;
    public comment?: string;
    public created?: Date;
}
