import { BaseModel } from 'src/app/_shared/models/_base.model';

export class UserStatsModel extends BaseModel {
    constructor(data?: UserStatsModel) {
        super(data); // console.log('UserStatsModel', this);
    }

    public friends?: number;
    public followers?: number;
    public following?: number;
    public challenges?: number;
    public wins?: number;
    public interests?: number;
}
