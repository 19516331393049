export class BaseModel {
    constructor(
        data?: any | string,
        dictionary: any = {}
    ) { // console.log('BaseClass', this, data);
        if (!data) return;
        switch (typeof data) {
            /* case 'string':
                Object.keys(dictionary).some((key: string) => { // console.log(key);
                    if (data === dictionary[key].char || data === dictionary[key].key) {
                        Object.assign(this, dictionary[key]);
                        return true;
                    }
                });
                break; */
            case 'object':
                // if (!dictionary) dictionary = {};
                Object.keys(data).forEach((key: string) => {
                    if (data[key] === null || data[key] === '\u0000') return;
                    this[key] = (() => { // console.log(dictionary[key]);
                        const modelMapper = (constructor, model) => {
                            if (constructor.name === 'Date') // Dates require only 1 param
                                return new constructor(model);
                            else if (constructor.prototype) // Class
                                return new constructor(model, dictionary);
                            else // Function
                                return constructor(model, dictionary);
                        };

                        if (Array.isArray(dictionary[key])) {
                            return data[key].map(item => modelMapper(dictionary[key][0], item));
                        } else if (dictionary[key] instanceof Function) {
                            return modelMapper(dictionary[key], data[key]);
                        } else { // Plain value
                            return data[key];
                        }
                    })();
                });
                break;
        }
    }
}
