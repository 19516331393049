import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseFilterMenuComponent } from 'src/app/_shared/components/base-filter.menu';
import { expandInOut } from 'src/app/_shared/animations';
import { BattleCriteriaModel } from 'src/app/battles/models/battle-criteria.model';
import { AuthService } from 'src/app/auth/auth.service';
import { BattleEntrantResponse, BattleEntrantResponses } from 'src/app/battles/models/battle-entrant-responses.dictionary';
import { BattleStatuses, BattleStatus } from 'src/app/battles/models/battle-statuses.dictionary';
import { LoadingController } from '@ionic/angular';


@Component({
    selector: 'battle-requests-filter',
    templateUrl: 'battle-requests-filter.component.html',
    animations: [expandInOut()]
})
export class BattleRequestsFilterComponent extends BaseFilterMenuComponent {
    constructor(
        // public route: ActivatedRoute,
        public authService: AuthService,
        public loadingController: LoadingController
    ) { super(loadingController); }

    public criterias: BattleCriteriaModel[] = [
        new BattleCriteriaModel({
            label: 'Received',
            /* order: [{
                key: 'newest',
                label: 'Newest'
            }], */
            filter: {
                key: 'received',
                entrants: [this.authService.user],
                statuses: [], // BattleStatuses.upcoming, BattleStatuses.inProgress, BattleStatuses.finished],
                responses: [], // BattleEntrantResponses.none, BattleEntrantResponses.accept]
            }
        }),
        new BattleCriteriaModel({
            label: 'Sent',
            /* order: [{
                key: 'newest',
                label: 'Newest'
            }], */
            filter: {
                key: 'sent',
                entrants: [this.authService.user],
                statuses: [], // BattleStatuses.upcoming, BattleStatuses.inProgress, BattleStatuses.finished],
                responses: [], // BattleEntrantResponses.none, BattleEntrantResponses.accept]
            }
        }),
    ];

    public setCriteria = (index: number) => {
        const criteria = this.criterias[index];
        this.statusOptions.forEach(option => {
            option.isSelected = !criteria.filter.statuses.length || !!criteria.filter.statuses.find(status => status.key === option.key);
        });
        this.responseOptions.forEach(option => {
            option.isSelected = !criteria.filter.responses.length || !!criteria.filter.responses.find(response => response.key === option.key);
        });

        // this.buttonLabel = criteria.label + ' Requests';
        // this.criteria.next(criteria);
        this.tempCriteria = criteria;
        this.slideTab = index;
        this.slideTabs.slideTo(index);
        this.criteria.next(this.tempCriteria); // this.setCriteria(this.tempCriteria);
        this.buildLabel();
    }

    public buildLabel = () => {
        this.buttonLabel = 'Filter'; // this.tempCriteria.label; //  + ' Requests';
    }


    public statusOptions: BattleStatus[] = [
        BattleStatuses.upcoming,
        BattleStatuses.inProgress,
        BattleStatuses.finished
    ];

    public responseOptions: BattleEntrantResponse[] = [
        BattleEntrantResponses.none,
        BattleEntrantResponses.accept,
        BattleEntrantResponses.decline
    ];
}
