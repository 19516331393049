import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { HttpService } from '../_shared/services/http.service';
import { UserModel } from './models/user.model';
import { ToastController } from '@ionic/angular';
import { BattleSuggestionModel } from '../battles/models/battle-suggestion.model';
import { BattleCategoryModel } from '../battles/models/battle-category.model';
import { map } from 'rxjs/operators';
import { UserCriteriaModel } from './models/user-criteria.model';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class UserService { // xtends BaseHubService {
    constructor(
        @Inject(DOCUMENT) public document: Document,
        private httpService: HttpService,
        private toastController: ToastController
        // private socketService: SocketService
    ) { // console.log('UserService');
    }

    public import(): Promise<any> {
        return this.httpService.request({
            url: '/api/user/importFriends',
            // query: new FriendCriteriaRequestModel(filter)
        }).toPromise()
        .then((response: any) => { // console.log(response);
            return response; // .map(friend => new FriendModel(friend));
        });
    }

    public follow(friends: any[]): Promise<any> {
        return this.httpService.post({
            url: '/api/user/follow',
            body: friends.map(friend => friend.userId)
        }).toPromise();
    }

    public unfollow(friends: any[]): Promise<any> {
        return this.httpService.post({
            url: '/api/user/unfollow',
            body: friends.map(friend => friend.userId)
        }).toPromise();
    }

    public async toggleFollowing(user: UserModel) {
        if (user.security.isFollowing)
            await this.unfollow([user]);
        else
            await this.follow([user]).then(() => {
                this.toastController.create({
                    message: 'You are now following ' + user.name,
                    duration: 5000
                }).then(toast => toast.present());
            });

        user.security.isFollowing = !user.security.isFollowing;
    }

    public getFollowers(userId: number) {
        return this.httpService.get<UserModel[]>({
            url: '/api/user/' + userId + '/followers',
        }).toPromise();
    }

    public getUser(userId: number, force?: boolean): Promise<UserModel> {
        return this.httpService.get('/api/user/' + userId, force).toPromise();
    }

    public getUsers(criteria: UserCriteriaModel) {
        return this.httpService.get<UserModel[]>({
            url: '/api/user/list',
            query: criteria
        })
        .pipe(map(response => { // console.log('gotUsers', response);
            return response.map(val => new UserModel(val));
        }));
    }

    public getStats(userId: number): Promise<UserModel> {
        return this.httpService.get('/api/user/' + userId + '/stats').toPromise();
    }

    public getInterests(): Promise<any> {
        return this.httpService.request({
            url: '/api/user/interests'
        }).toPromise()
        .then((response: any) => { // console.log(response);
            return response; // .map(friend => new FriendModel(friend));
        });
    }

    public toggleInterest(interest: BattleCategoryModel): Promise<boolean> {
        return this.httpService.post({
            url: '/api/user/interest/toggle/' + interest.battleCategoryId,
            query: interest.isFollowing ? { remove: true } : null
        })
        .toPromise()
        .then((response: boolean) => { // console.log(response);
            return response;
        });
    }
}
