import { UserModel } from 'src/app/users/models/user.model';
import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleModel } from './battle.model';


export class BattleLikeModel extends BaseModel {
    constructor(data?: BattleLikeModel) {
        super(data, {
            user: UserModel
        }); // console.log('BattleLikeModel', this);
    }

    public battleId?: number;
    public user?: UserModel;
    public battle?: BattleModel;
}
