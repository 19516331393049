import { format } from 'date-fns';
import { get } from 'lodash-es';

import { BaseModel } from 'src/app/_shared/models/_base.model';
import { BattleStatus, BattleStatuses } from './battle-statuses.dictionary';
import { BattleEntrantModel } from './battle-entrant.model';
import { BattleSecurityModel } from './battle-security.model';
import { BattleRoundModel } from './battle-round.model';
import { UserModel } from 'src/app/users/models/user.model';
import { BattleScoreOrder } from './battle-score-order.dictionary';
import { BattleScoreType, BattleScoreTypes } from './battle-score-type.dictionary';
import { BattleLikeModel } from './battle-like.model';
import { BattleCommentModel } from './battle-comment.model';
import { BattleActivityModel } from './battle-activity.model';
import { BattlePrivacy } from './battle-privacy.dictionary';
import { BattleStreamModel } from './battle-stream.model';


export class BattleModel extends BaseModel {
    constructor(data?: BattleModel, dictionary: any = {}) {
        super(data, Object.assign({
            battle: BattleModel, // for circular references

            activity: [BattleActivityModel],
            created: Date,
            status: BattleStatus,
            owner: UserModel,
            entrants: [BattleEntrantModel],
            likes: [BattleLikeModel],
            comments: [BattleCommentModel],
            responseDeadline: Date,
            rounds: [BattleRoundModel],
            security: BattleSecurityModel,
            scoreType: BattleScoreType,
            scoreOrder: BattleScoreOrder,
            streams: [BattleStreamModel],
            privacy: BattlePrivacy,
            umpires: [UserModel],
            when: Date,
        }, dictionary)); // console.log('BattleModel', this);

        // Calculated
        if (this.battleId) {
            this.subTitle = (() => {
                const response: string[] = [];
                if (this.status.key === BattleStatuses.upcoming.key && this.when)
                    response.push(format(this.when, 'MMM do, hh:mma'));
                if (this.status.key !== BattleStatuses.upcoming.key || !this.when)
                    response.push(this.status.label);

                if (get(this.scoreType, 'key') === BattleScoreTypes.points.key)
                    response.push('Points');
                if (get(this.scoreType, 'key') === BattleScoreTypes.timed.key)
                    response.push('Timed');

                if (this.isOpenInvite)
                    response.push('Open-Invite');
                if (this.hasCrowdVoting)
                    response.push('Crowd-Vote');

                response.push(this.privacy.label);

                return response.join(', ');
            })();
        }
    }

    public activity?: BattleActivityModel[];
    public battleId?: number;
    public comments?: BattleCommentModel[];
    public icon?: string;
    public what?: string;
    public how?: string;
    public where?: string;
    public when?: Date;
    public responseDeadline?: Date;
    public status?: BattleStatus;
    public owner?: UserModel;
    public scoreType?: BattleScoreType;
    public scoreOrder?: BattleScoreOrder;
    public rounds?: BattleRoundModel[];
    public totalRounds?: number;
    public currentRound?: number;
    public hasCrowdVoting?: boolean;
    public isOpenInvite?: boolean;
    public currentVote?: number;
    public roundMins?: number;
    public entrants?: BattleEntrantModel[];
    public umpires?: UserModel[];
    public leagueId?: number;
    public likes?: BattleLikeModel[];
    public likesCount?: number;
    public streams?: BattleStreamModel[];
    public commentsCount?: number;
    public eventId?: number;
    public created?: Date;
    // public modified?: Date;
    public privacy?: BattlePrivacy;
    public security?: BattleSecurityModel;
    // public votes?: BattleVoteModel;
    // public crowd?: any[];

    public subTitle?: string;
}
